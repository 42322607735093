import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { GiHamburgerMenu } from "react-icons/gi";
import useViewport from "hooks/useViewport";
import useScroll from "hooks/useScroll";
import CDN_HOST from "config";

import "./Header.scss";

interface HeaderProps {
  home?: boolean;
}
const Header = ({ home }: HeaderProps) => {
  const { t } = useTranslation("header");
  const [active, setIsActive] = React.useState(false);
  const handleToggleButton = () => setIsActive((prev) => !prev);
  const [viewport] = useViewport();
  const { yOffset } = useScroll();

  React.useEffect(() => {
    if (active && viewport !== "phone") {
      setIsActive(false);
    }
  }, [active, setIsActive, viewport]);

  return (
    <div
      className={classnames("Header", {
        active: active,
        "fixed-top": yOffset > 80,
        home: home,
      })}
    >
      <div className="brand-logo">
        <Link to="/">
          <img
            src={`${CDN_HOST}/images/logo-transparent.png`}
            alt="Primavera logo"
          />
        </Link>
        <button onClick={handleToggleButton} className="toggle-button">
          <GiHamburgerMenu size="25" />
        </button>
      </div>
      <div className="navbar-links">
        <ul>
          <li>
            <Link to="/">{t("header:home")}</Link>
          </li>
          <li>
            <Link to="/about-us">{t("header:aboutUs")}</Link>
            <div className="sub-menu">
              <ul>
                <li>
                  <Link to="/team">{t("header:team")}</Link>
                </li>
                <li>
                  <Link to="/testimonials">{t("header:testimonials")}</Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <Link to="/gallery">{t("header:gallery")}</Link>
          </li>
          <li>
            <Link to="/services">{t("header:services")}</Link>
            <div className="sub-menu">
              <ul>
                <li>
                  <Link to="/facilities">{t("header:facilities")}</Link>
                </li>
                <li>
                  <Link to="/all-inclusive">{t("header:allInclusive")}</Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <Link to="/tourism">{t("header:tourism")}</Link>
          </li>
          <li>
            <Link to="/contact-us">{t("header:contactUs")}</Link>
          </li>
          <li>
            <a href="https://wa.link/k2j21t" target="_blank" rel="noreferrer"className="link">
              Get a quote
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
