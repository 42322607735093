import CDN_HOST from "../config";

const galleryMock = [
  {
    url: `${CDN_HOST}/images/breastGallery1.png`,
    category: "breast",
    title: "Breast",
  },
  {
    url: `${CDN_HOST}/images/breastGallery2.png`,
    category: "breast",
    title: "Breast",
  },
  {
    url: `${CDN_HOST}/images/breastGallery3.png`,
    category: "breast",
    title: "Breast",
  },
  {
    url: `${CDN_HOST}/images/breastGallery4.png`,
    category: "breast",
    title: "Breast",
  },
  {
    url: `${CDN_HOST}/images/breastGallery5.png`,
    category: "breast",
    title: "Breast",
  },
  {
    url: `${CDN_HOST}/images/breastGallery6.png`,
    category: "breast",
    title: "Breast",
  },
  {
    url: `${CDN_HOST}/images/breastGallery7.png`,
    category: "breast",
    title: "Breast",
  },
  {
    url: `${CDN_HOST}/images/breastGallery8.png`,
    category: "breast",
    title: "Breast",
  },
  {
    url: `${CDN_HOST}/images/breastGallery9.png`,
    category: "breast",
    title: "Breast",
  },
  {
    url: `${CDN_HOST}/images/breastGallery10.png`,
    category: "breast",
    title: "Breast",
  },
  // {
  //   url: `${CDN_HOST}/images/brazilianButtLift1.png`,
  //   category: "body",
  //   title: "Brazilian Butt Lift",
  // },
  // {
  //   url: `${CDN_HOST}/images/brazilianButtLift2.png`,
  //   category: "body",
  //   title: "Brazilian Butt Lift",
  // },
  // {
  //   url: `${CDN_HOST}/images/brazilianButtLift3.png`,
  //   category: "body",
  //   title: "Brazilian Butt Lift",
  // },
  // {
  //   url: `${CDN_HOST}/images/brazilianButtLift4.png`,
  //   category: "body",
  //   title: "Brazilian Butt Lift",
  // },
  // {
  //   url: `${CDN_HOST}/images/tummyTuck1.png`,
  //   category: "body",
  //   title: "Tummy tuck",
  // },
  // {
  //   url: `${CDN_HOST}/images/tummyTuck2.png`,
  //   category: "body",
  //   title: "Tummy tuck",
  // },
  // {
  //   url: `${CDN_HOST}/images/tummyTuck3.png`,
  //   category: "body",
  //   title: "Tummy tuck",
  // },
  // {
  //   url: `${CDN_HOST}/images/tummyTuck4.png`,
  //   category: "body",
  //   title: "Tummy tuck",
  // },
  // {
  //   url: `${CDN_HOST}/images/tummyTuck5.png`,
  //   category: "body",
  //   title: "Tummy tuck",
  // },
  // {
  //   url: `${CDN_HOST}/images/tummyTuck6.png`,
  //   category: "body",
  //   title: "Tummy tuck",
  // },
  // {
  //   url: `${CDN_HOST}/images/liposuction1.png`,
  //   category: "body",
  //   title: "Liposuction",
  // },
  // {
  //   url: `${CDN_HOST}/images/liposuction2.png`,
  //   category: "body",
  //   title: "Liposuction",
  // },
  // {
  //   url: `${CDN_HOST}/images/liposuction3.png`,
  //   category: "body",
  //   title: "Liposuction",
  // },
  // {
  //   url: `${CDN_HOST}/images/liposuction4.png`,
  //   category: "body",
  //   title: "Liposuction",
  // },
  {
    url: `${CDN_HOST}/images/rhinoplasty1.png`,
    category: "face",
    title: "Rhinoplasty",
  },
  {
    url: `${CDN_HOST}/images/rhinoplasty2.png`,
    category: "face",
    title: "Rhinoplasty",
  },
  {
    url: `${CDN_HOST}/images/rhinoplasty3.png`,
    category: "face",
    title: "Rhinoplasty",
  },
  {
    url: `${CDN_HOST}/images/rhinoplasty4.png`,
    category: "face",
    title: "Rhinoplasty",
  },
  // {
  //   url: `${CDN_HOST}/images/facelift1.svg`,
  //   category: "face",
  //   title: "Face Lift",
  // },
  // {
  //   url: `${CDN_HOST}/images/facelift2.svg`,
  //   category: "face",
  //   title: "Face Lift",
  // },
  // {
  //   url: `${CDN_HOST}/images/facelift3.svg`,
  //   category: "face",
  //   title: "Face Lift",
  // },
  // {
  //   url: `${CDN_HOST}/images/facelift4.svg`,
  //   category: "face",
  //   title: "Face Lift",
  // },
  // {
  //   url: `${CDN_HOST}/images/blepharoplasty1.svg`,
  //   category: "face",
  //   title: "Blepharoplasty",
  // },
  // {
  //   url: `${CDN_HOST}/images/blepharoplasty2.svg`,
  //   category: "face",
  //   title: "Blepharoplasty",
  // },
  // {
  //   url: `${CDN_HOST}/images/blepharoplasty3.svg`,
  //   category: "face",
  //   title: "Blepharoplasty",
  // },
  // {
  //   url: `${CDN_HOST}/images/bucalFat1.svg`,
  //   category: "face",
  //   title: "Bucal Fat Removal",
  // },
  // {
  //   url: `${CDN_HOST}/images/bucalFat2.svg`,
  //   category: "face",
  //   title: "Bucal Fat Removal",
  // },
  // {
  //   url: `${CDN_HOST}/images/bucalFat3.svg`,
  //   category: "face",
  //   title: "Bucal Fat Removal",
  // },
  // {
  //   url: `${CDN_HOST}/images/bucalFat4.svg`,
  //   category: "face",
  //   title: "Bucal Fat Removal",
  // },
  // {
  //   url: `${CDN_HOST}/images/otoplasty1.svg`,
  //   category: "face",
  //   title: "Otoplasty",
  // },
  // {
  //   url: `${CDN_HOST}/images/otoplasty2.svg`,
  //   category: "face",
  //   title: "Otoplasty",
  // },
  // {
  //   url: `${CDN_HOST}/images/otoplasty3.svg`,
  //   category: "face",
  //   title: "Otoplasty",
  // },
  // {
  //   url: `${CDN_HOST}/images/otoplasty4.svg`,
  //   category: "face",
  //   title: "Otoplasty",
  // },
  // {
  //   url: `${CDN_HOST}/images/otoplasty5.svg`,
  //   category: "face",
  //   title: "Otoplasty",
  // },
  {
    url: `${CDN_HOST}/images/dentistry-1.png`,
    category: "dentistry",
    title: "Dentistry",
  },
  {
    url: `${CDN_HOST}/images/dentistry-2.png`,
    category: "dentistry",
    title: "Dentistry",
  },
  {
    url: `${CDN_HOST}/images/dentistry-3.png`,
    category: "dentistry",
    title: "Dentistry",
  },
  {
    url: `${CDN_HOST}/images/dentistry-4.png`,
    category: "dentistry",
    title: "Dentistry",
  },
  // {
  //   url: `${CDN_HOST}/images/lasikEye1.png`,
  //   category: "lasikEye",
  //   title: "Lasik Eye",
  // },
  // {
  //   url: `${CDN_HOST}/images/lasikEye2.png`,
  //   category: "lasikEye",
  //   title: "Lasik Eye",
  // },
  // {
  //   url: `${CDN_HOST}/images/lasikEye3.png`,
  //   category: "lasikEye",
  //   title: "Lasik Eye",
  // },
  // {
  //   url: `${CDN_HOST}/images/lasikEye4.png`,
  //   category: "lasikEye",
  //   title: "Lasik Eye",
  // },
];

export default galleryMock;
