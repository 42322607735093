import React from "react";
import { IoIosArrowUp } from "react-icons/io";
import styled from "styled-components";
import { colors } from "styles";

const UpArrowWrapper = styled.div`
  align-items: center;
  background-color: ${colors.secondary};
  border-radius: 50px;
  bottom: 10px;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 24px;
  height: 40px;
  justify-content: center;
  position: fixed;
  right: 12px;
  width: 40px;
  z-index: 3;
`;

export default function ScrollTop() {
  const [isVisible, setIsVisible] = React.useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  React.useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <>
      {isVisible && (
        <UpArrowWrapper onClick={scrollToTop}>
          <IoIosArrowUp />
        </UpArrowWrapper>
      )}
    </>
  );
}
