import React from "react";
import { useTranslation } from "react-i18next";
import { Banner } from "components/atoms";
import { Header } from "components/molecules";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { dentistryMock } from "data";

import "./Dentistry.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface SlickArrowPrevtProps {
  currentSlide?: any;
  slideCount?: number;
}

const SlickArrowPrev = ({
  currentSlide,
  slideCount,
  ...props
}: SlickArrowPrevtProps) => (
  <button
    {...props}
    className={"prev-arrow"}
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
  >
    <IoIosArrowBack size={30} />
  </button>
);

interface SlickArrowRightProps {
  currentSlide?: any;
  slideCount?: number;
}

const SlickArrowRight = ({
  currentSlide,
  slideCount = 0,
  ...props
}: SlickArrowRightProps) => (
  <button
    {...props}
    className={
      "next-arrow" + (currentSlide === slideCount - 1 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type="button"
  >
    <IoIosArrowForward size={30} />
  </button>
);

const Dentistry = () => {
  const { t } = useTranslation("dentistry");

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowPrev />,
  };
  return (
    <div className="Dentistry">
      <Header />
      <Banner title={t("dentistry:dentistry")}/>
    <div className="wrapper"> 
    <div className="info-container">
    <p className="title">{t("dentistry:dentistry")}</p>
      <p className="detail">{t("dentistry:dentistryDesc")}</p> 
      <ul className="primary-list-container">
          <li>{t("dentistry:dentistryListOne")}</li>
          <li>{t("dentistry:dentistryListTwo")}</li>
          <li>{t("dentistry:dentistryListThree")}</li>
          <li>{t("dentistry:dentistryListFour")}</li> 
          <li>{t("dentistry:dentistryListFive")}</li>
          <li>{t("dentistry:dentistryListSix")}</li>
          <li>{t("dentistry:dentistryListSeven")}</li>
          <li>{t("dentistry:dentistryListEight")}</li>
        </ul> 
        <p className="subtitle">{t("dentistry:smileTitle")}</p>
        <p className="detail">{t("dentistry:smileDesc")}</p> 
        <ul className="primary-list-container">
          <li>{t("dentistry:smileListOne")}</li>
          <li>{t("dentistry:smileListTwo")}</li>
          <li>{t("dentistry:smileListThree")}</li>
          <li>{t("dentistry:smileListFour")}</li>
        </ul>   
      <p className="third-subtitle">{t("dentistry:naturalTitle")}</p>   
      <p className="detail">{t("dentistry:naturalDesc")}</p>
      <p className="third-subtitle">{t("dentistry:qualityTitle")}</p>   
      <p className="detail">{t("dentistry:qualityDesc")}</p>
      <p className="third-subtitle">{t("dentistry:durableTitle")}</p>   
      <p className="detail">{t("dentistry:durableDesc")}</p>
      <p className="third-subtitle">{t("dentistry:reliableTitle")}</p>   
      <p className="detail">{t("dentistry:reliableDesc")}</p><br/><br/>
      <p className="subtitle">{t("dentistry:diffCompositeAndPorcelainTitle")}</p>   
      <p className="detail">{t("dentistry:diffCompositeAndPorcelainDesc")}</p>
      <p className="third-subtitle">{t("dentistry:timeTitle")}</p>   
      <p className="detail">{t("dentistry:timeDesc")}</p>
      <p className="third-subtitle">{t("dentistry:durabilityTitle")}</p>   
      <p className="detail">{t("dentistry:durabilityDesc")}</p>
      <p className="third-subtitle">{t("dentistry:aestheticsTitle")}</p>   
      <p className="detail">{t("dentistry:aestheticsDesc")}</p>
      <p className="third-subtitle">{t("dentistry:costTitle")}</p>   
      <p className="detail">{t("dentistry:costDesc")}</p>
    </div>
    <div className="link-container">
      <a href="https://wa.link/k2j21t" className="link">{t("dentistry:getAQuote")}</a>
    </div>
    <div className="slider-container">
          <Slider {...settings}>
            {dentistryMock.map((item, idx) => (
              <img src={item.url} key={idx} alt={item.title} />
            ))}
          </Slider>
          </div>
    </div>
    </div>
  )
}

export default Dentistry;