import styled from "styled-components";
import { device, fontWeight, fontFamily } from "styles";

export const CardContainer = styled.div`
  align-items: center;
  box-shadow: 0px 10px 30px rgba(141, 151, 158, 0.3);
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  @media ${device.medium} {
    margin-bottom: 0;
  }
`;

export const Image = styled.img`
  height: 192px;
  object-fit: cover;
  width: 100%;
`;

export const Name = styled.h3`
  font-family: ${fontFamily.primaryFont};
  font-size: 21px;
  font-weight: ${fontWeight.black};
  padding: 10px 16px 5px 16px;
  text-align: center;
`;

export const Text = styled.h5`
  font-family: ${fontFamily.secondaryFont};
  font-size: 14px;
  font-weight: ${fontWeight.black};
  padding: 5px 16px 20px 16px;
  text-align: center;
`;
