import React from "react";
import { Switch, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Banner } from "components/atoms";
import { Header, ServiceMenu } from "components/molecules";

import "./Body.scss";
import { TummyTuck } from "./TummyTuck";
import { Liposuction } from "./Liposuction";
import { BrazilianButtLift } from "./BrazilianButtLift";
import { BodyContouring } from "./BodyContouring";
import { LabiaReduction } from "./LabiaReduction";

const bodyMenuData = [
  {
    url: "/services/body/tummy-tuck",
    name: "Tummy Tuck",
  },
  {
    url: "/services/body/liposuction",
    name: "Liposuction",
  },
  {
    url: "/services/body/brazilian-butt-lift",
    name: "Brazilian Butt Lift",
  },
  {
    url: "/services/body/body-contouring",
    name: "Body Contouring",
  },
  {
    url: "/services/body/labia-reduction",
    name: "Labia Reduction",
  }
];

const Body = () => {
  const { t } = useTranslation("body");
  return (
    <div className="Body">
      <Header />
      <Banner title={t("body:body")} />
      <div className="wrapper">
      <ServiceMenu data={bodyMenuData} />
      <>
      <Switch>
      <Route  path="/services/body/tummy-tuck">
        <TummyTuck />
      </Route>
      <Route  path="/services/body/liposuction">
        <Liposuction />
      </Route>
      <Route  path="/services/body/brazilian-butt-lift">
        <BrazilianButtLift/>
      </Route>
      <Route  path="/services/body/body-contouring">
        <BodyContouring/>
      </Route>
      <Route  path="/services/body/labia-reduction">
        <LabiaReduction/>
      </Route>
    </Switch>
    </>
      </div>
    </div>
  );
};

export default Body;
