import React from "react";
import { useTranslation } from "react-i18next";
import { Banner } from "components/atoms";
import { Header } from "components/molecules";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { lasikEyeMock } from "data";

import "./LasikEye.scss"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface SlickArrowPrevtProps {
  currentSlide?: any;
  slideCount?: number;
}

const SlickArrowPrev = ({
  currentSlide,
  slideCount,
  ...props
}: SlickArrowPrevtProps) => (
  <button
    {...props}
    className={"prev-arrow"}
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
  >
    <IoIosArrowBack size={30} />
  </button>
);

interface SlickArrowRightProps {
  currentSlide?: any;
  slideCount?: number;
}

const SlickArrowRight = ({
  currentSlide,
  slideCount = 0,
  ...props
}: SlickArrowRightProps) => (
  <button
    {...props}
    className={
      "next-arrow" + (currentSlide === slideCount - 1 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type="button"
  >
    <IoIosArrowForward size={30} />
  </button>
);

const LasikEye = () => {
  const { t } = useTranslation("lasikEye");

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowPrev />,
  };

  return (
    <div className="LasikEye">
      <Header />
      <Banner title={t("lasikEye:lasikEyeTitle")}/>
    <div className="wrapper">
    <div className="info-container">
    <div className="main-container">
    <p className="title">{t("lasikEye:lasikEyeTitle")}</p>
    <p className="subtitle">{t("lasikEye:nearsightednessTitle")}</p>   
      <p className="detail">{t("lasikEye:nearsightednessDesc")}</p>
      <p className="subtitle">{t("lasikEye:farsightednessTitle")}</p> 
      <p className="detail">{t("lasikEye:farsightednessDesc")}</p>
      <p className="subtitle">{t("lasikEye:astigmatismTitle")}</p>   
      <p className="detail">{t("lasikEye:astigmatismDesc")}</p>
      <p className="subtitle">{t("lasikEye:whatIsLasikEye")}</p>   
      <p className="detail">{t("lasikEye:lasikEyeContent")}</p>
      <p className="subtitle">{t("lasikEye:benefitsTitle")}</p>   
      <p className="third-subtitle">{t("lasikEye:reasonOneTitle")}</p> 
      <p className="detail">{t("lasikEye:reasonOneDesc")}</p>
      <p className="third-subtitle">{t("lasikEye:reasonTwoTitle")}</p> 
      <p className="detail">{t("lasikEye:reasonTwoDesc")}</p>
      <p className="third-subtitle">{t("lasikEye:reasonThreeTitle")}</p> 
      <p className="detail">{t("lasikEye:reasonThreeDesc")}</p>
    </div>
    <div className="link-container">
      <a href="https://wa.link/k2j21t" className="link">{t("lasikEye:getAQuote")}</a>
    </div>
    <div className="slider-container">
          <Slider {...settings}>
            {lasikEyeMock.map((item, idx) => (
              <img src={item.url} key={idx} alt={item.title} />
            ))}
          </Slider>
          </div>
    </div>  
    </div>
    </div>
  )
}

export default LasikEye;