import React from "react";
import { Link } from "react-router-dom";
import { VerticalServiceCard } from "components/molecules";
import { HomeServicesContainer, ButtonContainer } from "./styles";
import { Wrapper } from "shared";
import { homeServicesMock } from "data";

const HomeServices = () => {
  return (
    <HomeServicesContainer>
      <Wrapper className="wrapper">
        <div className="services-container">
          {homeServicesMock.map((service) => (
            <VerticalServiceCard
              key={service.id}
              photo={service.photo}
              title={service.title}
              url={service.url}
            />
          ))}
        </div>
        <ButtonContainer>
          <Link to="/services">View all services</Link>
        </ButtonContainer>
      </Wrapper>
    </HomeServicesContainer>
  );
};

export default HomeServices;
