import React, { FC } from "react";
import { Link } from "react-router-dom";

import "./ServiceMenu.scss";

interface MenuProps {
  data: {
    url: string;
    name: string;
  }[];
}

const ServiceMenu: FC<MenuProps> = ({ data }) => (
  <div className="ServiceMenu">
    <ul>
      {data.map((menuData, index) => (
        <li key={`${index}_${menuData.name}`}>
          <Link to={menuData.url}>{menuData.name}</Link>
        </li>
      ))}
    </ul>
  </div>
);

export default ServiceMenu;
