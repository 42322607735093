import React from "react";
import { useTranslation } from "react-i18next";
import { Banner, ScrollToTop } from "components/atoms";
import { Header } from "components/molecules";
import CDN_HOST from "config";

import "./AllInclusive.scss";

const AllInclusive = () => {
  const { t } = useTranslation("allInclusive");
  return (
    <div className="AllInclusive">
      <ScrollToTop />
      <Header />
      <Banner title={t("allInclusive:title")} />
      <div className="wrapper">
        <div className="title-container">
          <p className="subtitle">{t("allInclusive:subtitle")}</p>
          <div className="separator"></div>
        </div>
        <p className="description">{t("allInclusive:allInclusiveDesc")}</p>
        <p className="description">{t("allInclusive:allInclusivePrice")}</p>
        <p className="price">$6500 CAD</p>
        <p className="or">{t("allInclusive:or")}</p>
        <p className="price">$5300 USD</p>
        <p className="disclaimer">{t("allInclusive:disclaimer")}</p>
        <div className="link-container">
          <a href="https://wa.link/k2j21t" className="link">{t("allInclusive:getAQuote")}</a>
        </div>
        <div className="img-container">
          <img
            src={`${CDN_HOST}/images/package-pre.png`}
            alt="Package Pre"
          />
          <img
            src={`${CDN_HOST}/images/package-post.png`}
            alt="Package Post"
          />
        </div>
      </div>
    </div>
  );
};

export default AllInclusive;
