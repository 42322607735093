import React from "react";
import { useTranslation } from "react-i18next";
import { Banner, ScrollToTop } from "components/atoms";
import { Header } from "components/molecules";

import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  const { t } = useTranslation("privacyPolicy");
  return (
    <div className="PrivacyPolicy">
      <ScrollToTop />
      <Header />
      <Banner title="Privacy policy"/>
      <div className="wrapper">
      <div className="title-container">
        <p className="title">{t("privacyPolicy:title")}</p>
        <div className="separator"></div>
      </div>
      <div className="info-container">
       <p className="detail">{t("privacyPolicy:infoOne")}</p>
       <p className="subtitle">{t("privacyPolicy:subtitle")}</p>
       <p className="detail">{t("privacyPolicy:infoTwo")}</p>
      </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;