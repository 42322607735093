import styled from "styled-components";
import { colors, device, fontFamily, fontWeight } from "styles";

export const TestimonialSlotContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: transparent;

  @media ${device.large} {
    background-color: ${colors.white};
    display: grid;
    grid-template-columns: 369px auto;
  }
`;

export const ClientImageContainer = styled.div`
  width: 200px;
  height: 200px;

  @media ${device.large} {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: auto;

    @media ${device.large} {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: ${colors.white};
  padding: 0 25px 25px 25px;
  border-right: 8px solid ${colors.secondary};

  @media ${device.large} {
    border-right: 8px solid ${colors.secondary};
    height: 446px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 531px;
      height: 8px;
      background-color: ${colors.secondary};
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 531px;
      height: 8px;
      background-color: ${colors.secondary};
    }
  }

  @media ${device.extraLarge} {
    &:before,
    &:after {
      width: 581px;
    }
  }
`;

export const ClientName = styled.p`
  color: ${colors.black};
  font-size: 22px;
  font-family: ${fontFamily.primaryFont};
  margin: 30px 0 20px 0;

  @media ${device.medium} {
    margin: 60px 0 50px 0;
  }
`;

export const ClientTestimonial = styled.p`
  color: ${colors.black};
  font-family: ${fontFamily.secondaryFont};
  font-size: 17px;
  font-weight: ${fontWeight.black};
`;
