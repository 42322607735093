import CDN_HOST from "config";

const homeViewGalleryMock = [
  {
    title: "Body",
    img: `${CDN_HOST}/images/bodyGallery1.png`,
  },
  {
    title: "Body",
    img: `${CDN_HOST}/images/bodyGallery2.png`,
  },
  {
    title: "Face",
    img: `${CDN_HOST}/images/rhinoplasty1.png`,
  },
  {
    title: "Face",
    img: `${CDN_HOST}/images/rhinoplasty2.png`,
  },
  {
    title: "Breast",
    img: `${CDN_HOST}/images/breastGallery1.png`,
  },
  {
    title: "Breast",
    img: `${CDN_HOST}/images/breastGallery2.png`,
  },
  {
    title: "Dentistry",
    img: `${CDN_HOST}/images/dentistry-1.png`,
  },
  {
    title: "Dentistry",
    img: `${CDN_HOST}/images/dentistry-2.png`,
  },
];

export default homeViewGalleryMock;