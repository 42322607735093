import CDN_HOST from "../config";

const rhinoplastyMock = [
  {
    url: `${CDN_HOST}/images/rhinoplasty1.png`,
    category: "Rhinoplasty",
    title: "Rhinoplasty",
  },
  {
    url: `${CDN_HOST}/images/rhinoplasty2.png`,
    category: "Rhinoplasty",
    title: "Rhinoplasty",
  },
  {
    url: `${CDN_HOST}/images/rhinoplasty3.png`,
    category: "Rhinoplasty",
    title: "Rhinoplasty",
  },
  {
    url: `${CDN_HOST}/images/rhinoplasty4.png`,
    category: "Rhinoplasty",
    title: "Rhinoplasty",
  },
];

export default rhinoplastyMock;