import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CDN_HOST from "config";

import { Wrapper } from "shared";
import "./Welcome.scss";

const Welcome = () => {
  const { t } = useTranslation("welcome");
  return (
    <Wrapper className="Welcome">
      <h3 className="title">{t("welcome:title")}</h3>
      <div className="welcome-to">
        <div className="info-container">
          <div className="description">
            <p>{t("welcome:info")}</p>
            <Link to="/tourism" className="button">
              Tourism
            </Link>
          </div>
        </div>

        <div className="img-container">
          <img
            src={`${CDN_HOST}/images/welcomeToPrimavera.png`}
            alt="Home welcome"
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default Welcome;
