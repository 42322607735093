import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Banner, ScrollToTop } from "components/atoms";
import { Header } from "components/molecules";
import { galleryMock } from "data";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Gallery.scss";
interface SlickArrowPrevtProps {
  currentSlide?: any;
  slideCount?: number;
}

const SlickArrowPrev = ({
  currentSlide,
  slideCount,
  ...props
}: SlickArrowPrevtProps) => (
  <button
    {...props}
    className={"prev-arrow"}
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
  >
    <IoIosArrowBack size={30} />
  </button>
);

interface SlickArrowRightProps {
  currentSlide?: any;
  slideCount?: number;
}

const SlickArrowRight = ({
  currentSlide,
  slideCount = 0,
  ...props
}: SlickArrowRightProps) => (
  <button
    {...props}
    className={
      "next-arrow" + (currentSlide === slideCount - 1 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type="button"
  >
    <IoIosArrowForward size={30} />
  </button>
);

enum Filters {
  BODY = "body",
  FACE = "face",
  BREAST = "breast",
  DENTISTRY = "dentistry",
  LASIK = "lasikEye",
}

const Gallery = () => {
  const [filteredImages, setFilteredImages] = useState(
    galleryMock.filter((img) => img.category === Filters.BODY)
  );
  const { t } = useTranslation("gallery");
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowPrev />,
  };

  const handleClickFilterOption = (filterOption: Filters) => {
    const imagenesYaFiltradas = galleryMock.filter(
      (img) => img.category === filterOption
    );

    setFilteredImages(imagenesYaFiltradas);
  };
  return (
    <div className="Gallery">
      <ScrollToTop />
      <Header />
      <Banner title="Gallery" />
      <div className="wrapper">
        <div className="title-container">
          <p className="title">{t("gallery:title")}</p>
          <div className="separator"></div>
        </div>
        <div className="buttons-container">
          <button onClick={() => handleClickFilterOption(Filters.BODY)}>
            {t("gallery:body")}
          </button>
          <button onClick={() => handleClickFilterOption(Filters.FACE)}>
            {t("gallery:face")}
          </button>
          <button onClick={() => handleClickFilterOption(Filters.BREAST)}>
            {t("gallery:breast")}
          </button>
          <button onClick={() => handleClickFilterOption(Filters.DENTISTRY)}>
            {t("gallery:dentistry")}
          </button>
          <button onClick={() => handleClickFilterOption(Filters.LASIK)}>
            {t("gallery:lasikEye")}
          </button>
        </div>
      </div>
      <div className="gallery-slider">
        <Slider {...settings}>
          {filteredImages.map((item, idx) => (
            <img
              key={`${item.url}_${idx}`}
              src={item.url}
              alt={item.title}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};
export default Gallery;
