import React from "react";
import { ScrollToTop } from "components/atoms";
import { Header } from "components/molecules";
import { HomeSlider } from "./HomeSlider";
import { Welcome } from "./Welcome";
import { ViewGallery } from "./ViewGallery";
import { WhyChooseUs } from "./WhyChooseUs";
import { ClientTestimonials } from "./ClientTestimonials";
import { Facilities } from "./Facilities";
import { useLocation } from "react-router-dom";

import "./Home.scss";
import { HomeServices } from "./HomeServices";

const Home = () => {
  const location = useLocation();
  console.log(location);
  return (
    <div className="Home">
      <ScrollToTop />
      <Header home />
      <HomeSlider />
      <HomeServices />
      <Welcome />
      <ViewGallery />
      <WhyChooseUs />
      <ClientTestimonials />
      <Facilities />
    </div>
  );
};
export default Home;
