import React from "react";
import { Link } from "react-router-dom";
import "./InfoCard.scss";

interface InfoCardProps {
  urlImg: string;
  title: string;
  linkTo: string;
}

const InfoCard = ({
  urlImg,
  title,
  linkTo,
}: InfoCardProps) => {
  return (
    <div className="InfoCard">
      <div className="img-container">
        <img src={urlImg} alt={title} />
      </div>
      <div className="info-container">
        <p className="title">{title}</p>
        <Link to={linkTo} className="read-more">
         Read more
        </Link>
      </div>
    </div>
  );
};

export default InfoCard;
