import CDN_HOST from "../config";

const servicesMock = [
  {
    urlImg: `${CDN_HOST}/images/bodyServices.png`,
    title: "Body",
    linkTo: "/services/body/tummy-tuck",
  },
  {
    urlImg: `${CDN_HOST}/images/faceServices.png`,
    title: "Face",
    linkTo: "/services/face/rhinoplasty",
  },
  {
    urlImg: `${CDN_HOST}/images/breastServices.png`,
    title: "Breast",
    linkTo: "/services/breast/breast-augmentation",
  },
  {
    urlImg: `${CDN_HOST}/images/dentistryServices.png`,
    title: "Dentistry",
    linkTo: "/services/dentistry",
  },
  {
    urlImg: `${CDN_HOST}/images/lasikEyeServices.png`,
    title: "Lasik Eye",
    linkTo: "/services/lasik-eye",
  },
];

export default servicesMock;
