import React from "react";
import { Switch, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Banner } from "components/atoms";
import { Header, ServiceMenu } from "components/molecules";

import "./Breast.scss";
import { BreastAugmentation } from "./BreastAugmentation";
import { BreastLift } from "./BreastLift";
import { BreastReduction} from "./BreastReduction";
import { Gynecomastia} from "./Gynecomastia";


const breastMenuData = [
  {
    url: "/services/breast/breast-augmentation",
    name: "Breast Augmentation",
  },
  {
    url: "/services/breast/breast-lift",
    name: "Breast Lift",
  },
  {
    url: "/services/breast/breast-reduction",
    name: "Breast Reduction",
  },
  {
    url: "/services/breast/gynecomastia",
    name: "Gynecomastia",
  },
];

const Breast = () => {
  const { t } = useTranslation("breast");
  return (
    <div className="Breast">
      <Header />
      <Banner title={t("breast:breast")} />
      <div className="wrapper">
      <ServiceMenu data={breastMenuData} />
      <>
      <Switch>
      <Route  path="/services/breast/breast-augmentation">
        <BreastAugmentation />
      </Route>
      <Route  path="/services/breast/breast-lift">
        <BreastLift />
      </Route>
      <Route  path="/services/breast/breast-reduction">
        <BreastReduction />
      </Route>
      <Route  path="/services/breast/gynecomastia">
        <Gynecomastia />
      </Route>
    </Switch>
    </>
      </div>
    </div>
  );
};

export default Breast;