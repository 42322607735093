export const colors = {
  primary: "#f5f3f3",
  secondary: "#ffd1ba",
  gradient: "#f8ddd1",
  title: "#dca489",
  black: "black",
  white: "white",
  grey: "#b7b7b7",
  grey11: "#918686",
};
