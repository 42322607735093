import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { TestimonialSlot } from "components/molecules";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { homeTestimonialsMock } from "data";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ClientTestimonials.scss";
interface SlickArrowPrevtProps {
  currentSlide?: any;
  slideCount?: number;
}

const SlickArrowPrev = ({
  currentSlide,
  slideCount,
  ...props
}: SlickArrowPrevtProps) => (
  <button
    {...props}
    className={"prev-arrow"}
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
  >
    <IoIosArrowBack size={20} />
  </button>
);

interface SlickArrowRightProps {
  currentSlide?: any;
  slideCount?: number;
}

const SlickArrowRight = ({
  currentSlide,
  slideCount = 0,
  ...props
}: SlickArrowRightProps) => (
  <button
    {...props}
    className={
      "next-arrow" + (currentSlide === slideCount - 1 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type="button"
  >
    <IoIosArrowForward size={20} />
  </button>
);

const ClientTestimonial = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowPrev />,
  };
  const { t } = useTranslation("clientTestimonials");
  return (
    <div className="ClientTestimonials">
      <div className="wrapper">
        <h1 className="title">{t("clientTestimonials:title")}</h1>
        <div className="gallery-slider">
          <Slider {...settings}>
            {homeTestimonialsMock.map((client, idx) => (
              <TestimonialSlot
                name={client.name}
                testimonial={client.testimonial}
                photoUrl={client.photoUrl}
                key={idx}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ClientTestimonial;
