import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { rhinoplastyMock } from "data";

import "./Rhinoplasty.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface SlickArrowPrevtProps {
  currentSlide?: any;
  slideCount?: number;
}

const SlickArrowPrev = ({
  currentSlide,
  slideCount,
  ...props
}: SlickArrowPrevtProps) => (
  <button
    {...props}
    className={"prev-arrow"}
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
  >
    <IoIosArrowBack size={30} />
  </button>
);

interface SlickArrowRightProps {
  currentSlide?: any;
  slideCount?: number;
}

const SlickArrowRight = ({
  currentSlide,
  slideCount = 0,
  ...props
}: SlickArrowRightProps) => (
  <button
    {...props}
    className={
      "next-arrow" + (currentSlide === slideCount - 1 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type="button"
  >
    <IoIosArrowForward size={30} />
  </button>
);


const Rhinoplasty: FC = () => {
  const { t } = useTranslation("face");

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowPrev />,
  };

  return (
    <div className="Rhinoplasty"> 
        <div className="info-container">
    <p className="title">{t("face:rhinoplastyTitle")}</p>
    <div className="main-content">
    <div className="primary-container">
      <div className="left">
        <img src="https://y3p8x5u4.rocketcdn.me/wp-content/uploads/2019/12/thumb.png" alt="Thumbs up"/>
        <div className="details">
          <p className="title-details">"Worth It" Rating</p>
          <div className="info-details">
            <p className="number">91%</p>
            <p className="detail">say it's "worth it"</p>
          </div>
        </div>
      </div>
      <div className="right">
        <img src="https://y3p8x5u4.rocketcdn.me/wp-content/uploads/2019/12/clock.png" alt="Surgery duration"/>
        <div className="details">
          <p className="title-details">{t("face:surgeryLength")}</p>
          <div className="info-details">
          <p className="number">4 {t("face:hours")}</p>
          </div>
        </div>
      </div>
    </div>
    <div className="bar-wrapper">
      <p className="title">{t("face:invasiveness")}</p>
      <div className="percentage-container">
        <div className="percentage-eight">
          <p>8/10</p>
        </div>
      </div>
    </div>
    <div className="bar-wrapper">
      <p className="title">{t("face:discomfortFactor")}</p>
      <div className="percentage-container">
        <div className="percentage-six">
          <p>6/10</p>
        </div>
      </div>
    </div>
    <div className="secondary-container">
      <div className="content">
        <p className="title-content">{t("face:sypnosis")}</p>
        <p className="info-content">{t("face:rhinoplastySypnosis")}</p>
      </div>
      <div className="content">
        <p className="title-content">{t("face:recovery")}</p>
        <p className="info-content">{t("face:rhinoplastyRecoveryTrip")}</p>
        <p className="info-content">{t("face:rhinoplastyRecoveryExercise")}</p>
      </div>
      <div className="content">
        <p className="title-content">{t("face:anesthesia")}</p>
        <p className="info-content">{t("face:generalAnesthesia")}</p>
      </div>
      <div className="content">
        <p className="title-content">{t("face:alternativeNames")}</p>
        <p className="info-content">{t("face:rhinoplastyAltNames")}</p>
      </div>
    </div>
    </div>
    <div className="definition-container">
      <p className="title-definition">{t("face:whatIsRhinoplastyTitle")}</p>
      <p className="definition">{t("face:rhinoplastyContent")}</p>
    </div>
    <div className="link-container">
      <a href="https://wa.link/k2j21t" className="link">{t("face:getAQuote")}</a>
    </div>
    <div className="slider-container">
          <Slider {...settings}>
            {rhinoplastyMock.map((item, idx) => (
              <img src={item.url} key={idx} alt={item.title} />
            ))}
          </Slider>
          </div>
    </div>
    </div>
  );
};

export default Rhinoplasty;