import CDN_HOST from "../config";

const liposuctionMock = [
  {
    url: `${CDN_HOST}/images/liposuction1.png`,
    category: "Liposuction",
    title: "Liposuction",
  },
  {
    url: `${CDN_HOST}/images/liposuction2.png`,
    category: "Liposuction",
    title: "Liposuction",
  },
  {
    url: `${CDN_HOST}/images/liposuction3.png`,
    category: "Liposuction",
    title: "Liposuction",
  },
  {
    url: `${CDN_HOST}/images/liposuction4.png`,
    category: "Liposuction",
    title: "Liposuction",
  },
];

export default liposuctionMock;