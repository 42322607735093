import CDN_HOST from "../config";

const faceLiftMock = [
  {
    url: `${CDN_HOST}/images/facelift1.svg`,
    category: "Face Lift",
    title: "Face Lift",
  },
  {
    url: `${CDN_HOST}/images/facelift2.svg`,
    category: "Face Lift",
    title: "Face Lift",
  },
  {
    url: `${CDN_HOST}/images/facelift3.svg`,
    category: "Face Lift",
    title: "Face Lift",
  },
  {
    url: `${CDN_HOST}/images/facelift4.svg`,
    category: "Face Lift",
    title: "Face Lift",
  },
];

export default faceLiftMock;