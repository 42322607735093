import styled from "styled-components";
import { colors, device, fontFamily, fontWeight } from "styles";

export const TeamContainer = styled.div`
  padding-bottom: 55px;
`;

export const TeamList = styled.div`
  align-items: center;
  align-items: stretch;
  display: grid;
  flex-direction: column;
  grid-template-columns: 212px;
  justify-content: center;
  margin-top: 40px;

  @media ${device.small} {
    display: grid;
    grid-template-columns: repeat(2, 212px);
    gap: 24px;
  }

  @media ${device.large} {
    grid-template-columns: repeat(3, 212px);
  }
`;

export const Title = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.title};
  font-family: ${fontFamily.primaryFont};
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0.9px;
  margin-bottom: 20px;
  padding: 20px 0 5px 0;
  text-transform: uppercase;

  @media ${device.medium} {
    font-size: 35px;
  }

  > .separator {
    background-color: ${colors.title};
    height: 4px;
    width: 80px;
  }
`;

export const Description = styled.div`
  margin-bottom: 20px;
  > p {
    font-family: ${fontFamily.secondaryFont};
    text-align: center;
    font-weight: ${fontWeight.black};
    font-size: 18px;
  }
`;
