import styled from "styled-components";
import { device, colors, fontFamily } from "styles";

export const HomeServicesContainer = styled.div`
  display: flex;

  .wrapper {
    padding: 0;

    @media ${device.large} {
      margin-top: -40px;
    }
    .services-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media ${device.medium} {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }

      @media ${device.large} {
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media ${device.medium} {
    height: 120px;
  }

  a {
    font-family: ${fontFamily.primaryFont};
    height: 42px;
    border: 1px solid ${colors.grey};
    padding: 10px 25px;
    font-size: 16px;
    color: ${colors.black};
    text-transform: uppercase;
    letter-spacing: 2px;
  }
`;
