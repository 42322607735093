import CDN_HOST from "../config";

const homeSliderMock = [
  {
    title: "Be your own eternal spring",
    description:
      "TOP Cosmetic Plastic Surgery & Tourism Packages In Colombia",
    img: `${CDN_HOST}/images/slider2.png`,
  },
  {
    title: "Be your own eternal spring",
    description:
      "TOP Cosmetic Plastic Surgery & Tourism Packages In Colombia",
    img: `${CDN_HOST}/images/slider1.png`,
  },
  {
    title: "Be your own eternal spring",
    description:
      "TOP Cosmetic Plastic Surgery & Tourism Packages In Colombia",
    img: `${CDN_HOST}/images/slider3.png`,
  },

  {
    title: "Be your own eternal spring",
    description:
      "TOP Cosmetic Plastic Surgery & Tourism Packages In Colombia",
    img: `${CDN_HOST}/images/slider4.png`,
  },
];

export default homeSliderMock;
