import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { homeFacilitiesMock } from "data";

import "./Facilities.scss";

const Facilities: React.FC = () => {
  const { t } = useTranslation("facilities");

  const facilities = homeFacilitiesMock.map((facilitie, idx) => (
    <Link to="/facilities" key={idx} className="facility">
      <img src={facilitie.img} alt="Facilitie" className="box" />
      <h3 className="title">{t(facilitie.title)}</h3>
    </Link>
  ));

  return (
    <div className="Facilities">
      <div className="main-container">
        <div className="title-container">
          <p className="title">{t("facilities:title")}</p>
          <div className="separator"></div>
        </div>
        <div className="facilities-container">{facilities}</div>
      </div>
    </div>
  );
};

export default Facilities;
