import React from "react";
import { useTranslation } from "react-i18next";
import { Banner, ScrollToTop } from "components/atoms";
import { Header } from "components/molecules";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { RiFacebookFill, RiInstagramFill } from "react-icons/ri";
import { VscMail } from "react-icons/vsc";

import { Wrapper } from "shared/index";
import "./ContactUs.scss";

const ContactUs = () => {
  const { t } = useTranslation("contactUs");

  return (
    <div className="ContactUs">
      <ScrollToTop />
      <Header />
      <Banner title="Contact us" />
      <Wrapper className="contact-wrapper">
        <div className="title-container">
          <p className="title">{t("contactUs:title")}</p>
          <p className="title">{t("contactUs:subTitle")}</p>
          <div className="separator"></div>
        </div>
        <div className="contact-container">
          <div className="social-card">
            <a href="mailto:info@primaverasurgical.com" className="icon">
                <VscMail size="22" />
            </a>
            <a href="mailto:info@primaverasurgical.com" className="info">
                <p>info@primaverasurgical.com</p>
            </a>
          </div>
          <div className="social-card">
            <a href="https://wa.link/k2j21t" 
               target="_blank"
               rel="noreferrer"
               className="icon"
            >
                <AiOutlineWhatsApp size="22" />
            </a>
            <a href="https://wa.link/k2j21t" 
               target="_blank"
               rel="noreferrer"
               className="info"
            >
                <p>1 647 401 2395</p>
            </a>            
          </div>
          <div className="social-card">
            <a
              href="https://www.instagram.com/primaverasurgical_"
              target="_blank"
              rel="noreferrer"
              className="icon"
            >
                <RiInstagramFill size="22" />
            </a>
            <a
              href="https://www.instagram.com/primaverasurgical_"
              target="_blank"
              rel="noreferrer"
              className="info"
            >
                <p>@primaverasurgical_</p>
            </a>
          </div>
          <div className="social-card">
            <a
              href="https://www.facebook.com/primaverasurgical"
              target="_blank"
              rel="noreferrer"
              className="icon"
            >
                <RiFacebookFill size="22" />
            </a>
            <a
              href="https://www.facebook.com/primaverasurgical"
              target="_blank"
              rel="noreferrer"
              className="info"
            >
                <p>primaverasurgical</p>
            </a>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};
export default ContactUs;
