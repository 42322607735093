import CDN_HOST from "../config";

const blepharoplastyMock = [
  {
    url: `${CDN_HOST}/images/blepharoplasty1.svg`,
    category: "blepharoplasty",
    title: "Blepharoplasty",
  },
  {
    url: `${CDN_HOST}/images/blepharoplasty2.svg`,
    category: "blepharoplasty",
    title: "Blepharoplasty",
  },
  {
    url: `${CDN_HOST}/images/blepharoplasty3.svg`,
    category: "blepharoplasty",
    title: "Blepharoplasty",
  },
];

export default blepharoplastyMock;