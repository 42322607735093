import React from "react";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Button } from "shared";
import useViewport from "hooks/useViewport";
import { homeSliderMock } from "data";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HomeSlider.scss";

interface ItemProps {
  title: string;
  description: string;
  img: string;
}

const Item = ({ title, description, img }: ItemProps) => {
  return (
    <>
      <div className="content">
        <div>
          <h3>{description}</h3>
          <h1 color="white">{title}</h1>
          <Button to="about-us">Read more</Button>
        </div>
        <img src={img} alt={title} />
      </div>
      <div className="slide-overlay" />
    </>
  );
};

interface SlickArrowPrevtProps {
  currentSlide?: any;
  slideCount?: number;
}

const SlickArrowPrev = ({
  currentSlide,
  slideCount,
  ...props
}: SlickArrowPrevtProps) => {
  const [viewport] = useViewport();
  return (
    <button
      {...props}
      className={"prev-arrow"}
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <IoIosArrowBack size={viewport === "phone" ? 20 : 30} />
    </button>
  );
};

interface SlickArrowRightProps {
  currentSlide?: any;
  slideCount?: number;
}

const SlickArrowRight = ({
  currentSlide,
  slideCount = 0,
  ...props
}: SlickArrowRightProps) => {
  const [viewport] = useViewport();

  return (
    <button
      {...props}
      className={
        "next-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <IoIosArrowForward size={viewport === "phone" ? 20 : 30} />
    </button>
  );
};

const HomeSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowPrev />,
  };
  return (
    <div className="HomeSlider">
      <Slider {...settings}>
        {homeSliderMock.map((item, idx) => (
          <Item
            key={idx}
            title={item.title}
            description={item.description}
            img={item.img}
          />
        ))}
      </Slider>
    </div>
  );
};

export default HomeSlider;
