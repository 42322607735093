import CDN_HOST from "../config";

const teamMock = [
  {
    name: "Melissa Rios",
    title: "Director of Primavera Surgical & Tourism",
    subTitle: "Certified Travel Advisor | Surgical Travel Facilitator",
    photo: `${CDN_HOST}/images/MelissaRios.jpeg`,
  },
  {
    name: "Dr. Luis Sanchez",
    title: "Aesthetic Dentist",
    subTitle: "Specializes in Healthy Dentistry | Smile designs",
    photo: `${CDN_HOST}/images/drLuisSanchez.jpeg`,
  },
  {
    name: "Dr. Jenny Duque",
    title: "General Dentist",
    subTitle:
      "Specializes in Oral Implantology | Aesthetic Dentistry | Periodontics & Endodontics | orthodontist",
    photo: `${CDN_HOST}/images/drJennyDuque.jpg`,
  },
  {
    name: "Dr. Carlos Betancur",
    title: "Plastic Certified Surgeon",
    subTitle:
      "Specializes in all Plastic Surgery procedures | certified by the Colombian Association of Plastic Surgeons",
    photo: `${CDN_HOST}/images/drCarlosBetancur.jpg `,
  },
  {
    name: "Dr. Boris Ramirez Serafinoff",
    title: "Ophthalmologist, Refractive Surgeon & Oculofacial Plastic Surgeon",
    subTitle:
      "Specializes in Lasik Eye| cosmetic and reconstructive aspects of the eyelids| Vision Care",
    photo: `${CDN_HOST}/images/drBorisRamirez.jpeg`,
  },
];

export default teamMock;
