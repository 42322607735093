import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { brazilianButtLiftMock } from "data";

import "./BrazilianButtLift.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface SlickArrowPrevtProps {
  currentSlide?: any;
  slideCount?: number;
}

const SlickArrowPrev = ({
  currentSlide,
  slideCount,
  ...props
}: SlickArrowPrevtProps) => (
  <button
    {...props}
    className={"prev-arrow"}
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
  >
    <IoIosArrowBack size={30} />
  </button>
);

interface SlickArrowRightProps {
  currentSlide?: any;
  slideCount?: number;
}

const SlickArrowRight = ({
  currentSlide,
  slideCount = 0,
  ...props
}: SlickArrowRightProps) => (
  <button
    {...props}
    className={
      "next-arrow" + (currentSlide === slideCount - 1 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type="button"
  >
    <IoIosArrowForward size={30} />
  </button>
);

const BrazilianButtLift: FC = () => {
  const { t } = useTranslation("body");

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowPrev />,
  };

  return (
    <div className="BrazilianButtLift">
        <div className="info-container">
          <div className="main-content">
     <p className="title">{t("body:brazilianTitle")}</p>
            <div className="primary-container">
              <div className="left">
                <img
                  src="https://y3p8x5u4.rocketcdn.me/wp-content/uploads/2019/12/thumb.png"
                  alt="Thumbs up"
                />
                <div className="details">
                  <p className="title-details">
                    "{t("body:worthIt")}" {t("body:rating")}
                  </p>
                  <div className="info-details">
                    <p className="number">88%</p>
                    <p className="detail">say it's "worth it"</p>
                  </div>
                </div>
              </div>
              <div className="right">
                <img
                  src="https://y3p8x5u4.rocketcdn.me/wp-content/uploads/2019/12/clock.png"
                  alt="Surgery duration"
                />
                <div className="details">
                  <p className="title-details">{t("body:surgeryLength")}</p>
                  <div className="info-details">
                    <p className="number">2 {t("body:hours")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bar-wrapper">
              <p className="title">{t("body:invasiveness")}</p>
              <div className="percentage-container">
                <div className="percentage-six">
                  <p>6/10</p>
                </div>
              </div>
            </div>
            <div className="bar-wrapper">
              <p className="title">{t("body:discomfortFactor")}</p>
              <div className="percentage-container">
                <div className="percentage-six">
                  <p>6/10</p>
                </div>
              </div>
            </div>
            <div className="secondary-container">
              <div className="content">
                <p className="title-content">{t("body:sypnosis")}</p>
                <p className="info-content">{t("body:brazilianSypnosis")}</p>
              </div>
              <div className="content">
                <p className="title-content">{t("body:recovery")}</p>
                <p className="info-content">
                  {t("body:brazilianRecoveryTrip")}
                </p>
                <p className="info-content">
                  {t("body:brazilianRecoveryExercise")}
                </p>
              </div>
              <div className="content">
                <p className="title-content">{t("body:anesthesia")}</p>
                <p className="info-content">{t("body:generalAnesthesia")}</p>
              </div>
              <div className="content">
                <p className="title-content">{t("body:alternativeNames")}</p>
                <p className="info-content">{t("body:brazilianAltNames")}</p>
              </div>
            </div>
          </div>
          <div className="definition-container">
            <p className="title-definition">{t("body:whatIsBrazilianTitle")}</p>
            <p className="definition">{t("body:brazilianContent")}</p>
          </div>
          <div className="link-container">
              <a href="https://wa.link/k2j21t" className="link">{t("body:getAQuote")}</a>
            </div>
          <div className="slider-container">
          <Slider {...settings}>
            {brazilianButtLiftMock.map((item, idx) => (
              <img src={item.url} key={idx} alt={item.title} />
            ))}
          </Slider>
          </div>
        </div>
    </div>
  );
};

export default BrazilianButtLift;