import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Wrapper } from "shared";
import { homeViewGalleryMock } from "data";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ViewGallery.scss";
interface SlickArrowPrevtProps {
  currentSlide?: any;
  slideCount?: number;
}

const SlickArrowPrev = ({
  currentSlide,
  slideCount,
  ...props
}: SlickArrowPrevtProps) => (
  <button
    {...props}
    className={"prev-arrow"}
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
  >
    <IoIosArrowBack size={30} />
  </button>
);

interface SlickArrowRightProps {
  currentSlide?: any;
  slideCount?: number;
}

const SlickArrowRight = ({
  currentSlide,
  slideCount = 0,
  ...props
}: SlickArrowRightProps) => (
  <button
    {...props}
    className={
      "next-arrow" + (currentSlide === slideCount - 1 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type="button"
  >
    <IoIosArrowForward size={30} />
  </button>
);

const ViewGallery = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowPrev />,
  };
  const { t } = useTranslation("viewGallery");
  return (
    <div className="ViewGallery">
      <Wrapper className="container">
        <p className="title">{t("viewGallery:title")}</p>
        <div className="gallery-slider">
          <Slider {...settings}>
            {homeViewGalleryMock.map((item, idx) => (
              <img src={item.img} key={idx} alt={item.title} />
            ))}
          </Slider>
        </div>
      </Wrapper>
    </div>
  );
};

export default ViewGallery;
