import React from "react";
import { Switch, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Banner } from "components/atoms";
import { Header, ServiceMenu } from "components/molecules";

import "./Face.scss";
import { Rhinoplasty } from "./Rhinoplasty";
import { FaceLift } from "./FaceLift";
import { Blepharoplasty} from "./Blepharoplasty";
import { BucalFatRemoval} from "./BucalFatRemoval";
import { Otoplasty} from "./Otoplasty";
import { NonSurgicalProcedures} from "./NonSurgicalProcedures";


const faceMenuData = [
  {
    url: "/services/face/rhinoplasty",
    name: "Rhinoplasty",
  },
  {
    url: "/services/face/face-lift",
    name: "Face Lift",
  },
  {
    url: "/services/face/blepharoplasty",
    name: "Blepharoplasty",
  },
  {
    url: "/services/face/bucal-fat-removal",
    name: "Bucal fat removal",
  },
  {
    url: "/services/face/otoplasty",
    name: "Otoplasty",
  },
  {
    url: "/services/face/non-surgical-procedures",
    name: "Non surgical procedures",
  },
];

const Face = () => {
  const { t } = useTranslation("face");
  return (
    <div className="Face">
      <Header />
      <Banner title={t("face:face")} />
      <div className="wrapper">
      <ServiceMenu data={faceMenuData} />
      <>
      <Switch>
      <Route  path="/services/face/rhinoplasty">
        <Rhinoplasty />
      </Route>
      <Route  path="/services/face/face-lift">
        <FaceLift />
      </Route>
      <Route  path="/services/face/blepharoplasty">
        <Blepharoplasty />
      </Route>
      <Route  path="/services/face/bucal-fat-removal">
        <BucalFatRemoval />
      </Route>
      <Route  path="/services/face/otoplasty">
        <Otoplasty />
      </Route>
      <Route  path="/services/face/non-surgical-procedures">
        <NonSurgicalProcedures/>
      </Route>
    </Switch>
    </>
      </div>
    </div>
  );
};

export default Face;