import CDN_HOST from "../config";

const brazilianButtLiftMock = [
  {
    url: `${CDN_HOST}/images/brazilianButtLift1.png`,
    category: "Brazilian Butt Lift",
    title: "Brazilian Butt Lift",
  },
  {
    url: `${CDN_HOST}/images/brazilianButtLift2.png`,
    category: "Brazilian Butt Lift",
    title: "Brazilian Butt Lift",
  },
  {
    url: `${CDN_HOST}/images/brazilianButtLift3.png`,
    category: "Brazilian Butt Lift",
    title: "Brazilian Butt Lift",
  },
  {
    url: `${CDN_HOST}/images/brazilianButtLift4.png`,
    category: "Brazilian Butt Lift",
    title: "Brazilian Butt Lift",
  },
];

export default brazilianButtLiftMock;