import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import "./Gynecomastia.scss";

const Gynecomastia: FC = () => {
  const { t } = useTranslation("breast");

  return (
    <div className="Gynecomastia"> 
    <div className="info-container">
    <p className="title">{t("breast:gynecomastiaTitle")}</p>
    <div className="main-content">
    <div className="primary-container">
      <div className="left">
        <img src="https://y3p8x5u4.rocketcdn.me/wp-content/uploads/2019/12/thumb.png" alt="Thumbs up"/>
        <div className="details">
          <p className="title-details">"{t("breast:worthIt")}" {t("breast:rating")}</p>
          <div className="info-details">
            <p className="number">96%</p>
            <p className="detail">say it's "worth it"</p>
          </div>
        </div>
      </div>
      <div className="right">
        <img src="https://y3p8x5u4.rocketcdn.me/wp-content/uploads/2019/12/clock.png" alt="Surgery duration"/>
        <div className="details">
          <p className="title-details">{t("breast:surgeryLength")}</p>
          <div className="info-details">
          <p className="number">1 {t("breast:hour")}</p>
          </div>
        </div>
      </div>
    </div>
    <div className="bar-wrapper">
      <p className="title">{t("breast:invasiveness")}</p>
      <div className="percentage-container">
        <div className="percentage-five">
          <p>5/10</p>
        </div>
      </div>
    </div>
    <div className="bar-wrapper">
      <p className="title">{t("breast:discomfortFactor")}</p>
      <div className="percentage-container">
        <div className="percentage-four">
          <p>4/10</p>
        </div>
      </div>
    </div>
    <div className="secondary-container">
      <div className="content">
        <p className="title-content">{t("breast:sypnosis")}</p>
        <p className="info-content">{t("breast:gynecomastiaSypnosis")}</p>
      </div>
      <div className="content">
        <p className="title-content">{t("breast:recovery")}</p>
        <p className="info-content">{t("breast:gynecomastiaRecoveryTrip")}</p>
        <p className="info-content">{t("breast:gynecomastiaRecoveryExercise")}</p>
      </div>
      <div className="content">
        <p className="title-content">{t("breast:anesthesia")}</p>
        <p className="info-content">{t("breast:generalAnesthesia")}</p>
      </div>
      <div className="content">
        <p className="title-content">{t("breast:alternativeNames")}</p>
        <p className="info-content">{t("breast:gynecomastiaAltNames")}</p>
      </div>
    </div>
    </div>
    <div className="definition-container">
      <p className="title-definition">{t("breast:whatIsGynecomastiaTitle")}</p>
      <p className="definition">{t("breast:gynecomastiaContent")}</p>
    </div>
    <div className="link-container">
      <a href="https://wa.link/k2j21t" className="link">{t("breast:getAQuote")}</a>
    </div>
    </div>
    </div>
  );
};

export default Gynecomastia;