import React from "react";
import {
  VerticalServiceCardContainer,
  CardContent,
  CustomBorderLeft,
  CustomBorderRight,
} from "./styles";

interface VerticalServiceCardProps {
  photo: string;
  title: string;
  url: string;
}

const VerticalServiceCard = ({
  photo,
  title,
  url,
}: VerticalServiceCardProps) => {
  return (
    <VerticalServiceCardContainer to={url}>
      <img src={photo} alt={title} />
      <CustomBorderLeft />
      <CustomBorderRight />
      <span />
      <CardContent>
        <h3>{title}</h3>
        <p>Surgery</p>
      </CardContent>
      <div className="img-overlay" />
    </VerticalServiceCardContainer>
  );
};

export default VerticalServiceCard;
