import React from "react";
import { CardContainer, Image, Name, Text } from "./styles";

interface TeamCardProps {
  name: string;
  photo: string;
  subTitle: string;
  title: string;
}

const TeamCard = ({ name, photo, subTitle, title }: TeamCardProps) => {
  return (
    <CardContainer>
      <Image src={photo} alt={name} />
      <Name>{name}</Name>
      <Text>{title}</Text>
      <Text>{subTitle}</Text>
    </CardContainer>
  );
};

export default TeamCard;
