import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import "./BodyContouring.scss";

const BodyContouring: FC = () => {
  const { t } = useTranslation("body");

  return (
    <div className="BodyContouring">
        <div className="info-container">
          <p className="title">{t("body:bodyContouringTitle")}</p>
          <div className="main-content">
            <div className="primary-container">
              <div className="left">
                <img
                  src="https://y3p8x5u4.rocketcdn.me/wp-content/uploads/2019/12/thumb.png"
                  alt="Thumbs up"
                />
                <div className="details">
                  <p className="title-details">
                    "{t("body:worthIt")}" {t("body:rating")}
                  </p>
                  <div className="info-details">
                    <p className="number">96%</p>
                    <p className="detail">say it's "worth it"</p>
                  </div>
                </div>
              </div>
              <div className="right">
                <img
                  src="https://y3p8x5u4.rocketcdn.me/wp-content/uploads/2019/12/clock.png"
                  alt="Surgery duration"
                />
                <div className="details">
                  <p className="title-details">{t("body:surgeryLength")}</p>
                  <div className="info-details">
                    <p className="number">3 {t("body:hours")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bar-wrapper">
              <p className="title">{t("body:invasiveness")}</p>
              <div className="percentage-container">
                <div className="percentage-ten">
                  <p>10/10</p>
                </div>
              </div>
            </div>
            <div className="bar-wrapper">
              <p className="title">{t("body:discomfortFactor")}</p>
              <div className="percentage-container">
                <div className="percentage-eight">
                  <p>8/10</p>
                </div>
              </div>
            </div>
            <div className="secondary-container">
              <div className="content">
                <p className="title-content">{t("body:sypnosis")}</p>
                <p className="info-content">
                  {t("body:bodyContouringSypnosis")}
                </p>
              </div>
              <div className="content">
                <p className="title-content">{t("body:recovery")}</p>
                <p className="info-content">
                  {t("body:bodyContouringRecoveryTrip")}
                </p>
                <p className="info-content">
                  {t("body:bodyContouringRecoveryExercise")}
                </p>
              </div>
              <div className="content">
                <p className="title-content">{t("body:anesthesia")}</p>
                <p className="info-content">{t("body:generalAnesthesia")}</p>
              </div>
              <div className="content">
                <p className="title-content">{t("body:alternativeNames")}</p>
                <p className="info-content">
                  {t("body:bodyContouringAltNames")}
                </p>
              </div>
            </div>
          </div>
          <div className="definition-container">
            <p className="title-definition">
              {t("body:whatIsBodyContouringTitle")}
            </p>
            <p className="definition">{t("body:bodyContouringContent")}</p>
            <p className="subtitle">{t("body:armLiftTitle")}</p>
            <p className="detail">{t("body:armLiftDesc")}</p>
            <p className="subtitle">{t("body:breastLiftTitle")}</p>
            <p className="detail">{t("body:breastLiftDesc")}</p>
            <p className="subtitle">{t("body:tummyTuckTitle")}:</p>
            <p className="detail">{t("body:tummyTuckDesc")}</p>
            <p className="subtitle">{t("body:buttockLiftTitle")}</p>
            <p className="detail">{t("body:buttockLiftDesc")}</p>
            <p className="subtitle">{t("body:thighLiftTitle")}</p>
            <p className="detail">{t("body:thighLiftDesc")}</p>
            <br />
            <p className="detail">{t("body:takeNote")}</p>
          </div>
          <div className="link-container">
              <a href="https://wa.link/k2j21t" className="link">{t("body:getAQuote")}</a>
            </div>
          <div className="slider-container">
          </div>
        </div>
    </div>
  );
};

export default BodyContouring;