import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import "./NonSurgicalProcedures.scss";

const NonSurgicalProcedures: FC = () => {
  const { t } = useTranslation("face");

  return (
    <div className="NonSurgicalProcedures"> 
    <div className="info-container">
    <p className="title">{t("face:nonSurgicalTitle")}</p>
    <div className="extra-container">
      <p className="detail">{t("face:nonSurgicalDesc")}</p>
      <p className="subtitle">{t("face:nonSurgicalSubtitle")}</p> 
      <ul className="primary-list-container">
          <li>{t("face:nonSurgicalListOne")}</li>
          <li>{t("face:nonSurgicalListTwo")}</li>
          <li>{t("face:nonSurgicalListThree")}</li>
          <li>{t("face:nonSurgicalListFour")}</li> 
          <li>{t("face:nonSurgicalListFive")}</li>
          <li>{t("face:nonSurgicalListSix")}</li>
          <li>{t("face:nonSurgicalListSeven")}</li>
          <li>{t("face:nonSurgicalListEight")}</li>
          <li>{t("face:nonSurgicalListNine")}</li>
          <li>{t("face:nonSurgicalListTen")}</li>
          <li>{t("face:nonSurgicalListEleven")}</li>
        </ul> 
    </div>
    <div className="link-container">
      <a href="https://wa.link/k2j21t" className="link">{t("face:getAQuote")}</a>
    </div>
    </div>
    </div>
  );
};

export default NonSurgicalProcedures;