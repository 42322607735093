import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { RiFacebookFill, RiInstagramFill } from "react-icons/ri";

import "./Footer.scss";

const Footer = () => {
  const { t } = useTranslation("footer");
  return (
    <div className="Footer">
      <div className="wrapper">
        <div className="top-footer">
          <div className="info-wrapper first">
            <div className="icon-container">
              <a href="https://wa.link/k2j21t" className="icon-container">
                <AiOutlineWhatsApp size="23" />
              </a>
            </div>
            <div className="info-wrapper-description">
              <p className="title-description">{t("footer:callUs")}</p>
              <p className="description">1 647 401 2395</p>
            </div>
          </div>
          <div className="info-wrapper last">
            <div className="icon-container">
              <div className="icon-container">
                <MdEmail size="23" />
              </div>
            </div>
            <div className="info-wrapper-description">
              <p className="title-description">{t("footer:emailUs")}</p>
              <p className="description">info@primaverasurgical.com</p>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <div className="map-section">
            <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31728.978337352168!2d-75.58610660453827!3d6.247611669618003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e4428dfb80fad05%3A0x42137cfcc7b53b56!2sMedell%C3%ADn%2C%20Antioquia%2C%20Colombia!5e0!3m2!1ses-419!2sve!4v1620870526150!5m2!1ses-419!2sve"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                loading="lazy"
                title="Map"
              ></iframe>
            </div>
          </div>
          <div className="info-section">
            <div className="quick-links">
              <p className="title">{t("footer:quickLinks")}</p>
              <div className="separator"></div>
              <ul className="links-container">
                <li>
                  <Link to="/" className="link">
                    {t("header:home")}
                  </Link>
                </li>
                <li>
                  <Link to="/about-us" className="link">
                    {t("header:aboutUs")}
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/team" className="sub-link">
                        {t("header:team")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/testimonials" className="sub-link">
                        {t("header:testimonials")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/gallery" className="link">
                    {t("header:gallery")}
                  </Link>
                </li>
                <li>
                  <Link to="/services" className="link">
                    {t("header:services")}
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/facilities" className="sub-link">
                        {t("header:facilities")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/all-inclusive" className="sub-link">
                        {t("header:allInclusive")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/tourism" className="link">
                    {t("header:tourism")}
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us" className="link">
                    {t("header:contactUs")}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="our-services">
              <p className="title">{t("footer:ourServices")}</p>
              <div className="separator"></div>
              <ul className="links-container">
                <li>
                  <Link to="/privacy-policy" className="link">
                    {t("footer:privacyPolicy")}
                  </Link>
                </li>
              </ul>
              <div className="social-links">
                <div className="social-container">
                  <a
                    href="https://www.facebook.com/primaverasurgical/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <RiFacebookFill size="22" />
                  </a>
                </div>
                <div className="social-container">
                  <a
                    href="https://www.instagram.com/primavera__surgical/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <RiInstagramFill size="22" />
                  </a>
                </div>
                <div className="social-container">
                  <a
                    href="https://wa.link/k2j21t"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <AiOutlineWhatsApp size="22" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
