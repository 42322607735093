import CDN_HOST from "../config";

const tummyTuckMock = [
  {
    url: `${CDN_HOST}/images/tummyTuck1.png`,
    category: "Tummy tuck",
    title: "Tummy tuck",
  },
  {
    url: `${CDN_HOST}/images/tummyTuck2.png`,
    category: "Tummy tuck",
    title: "Tummy tuck",
  },
  {
    url: `${CDN_HOST}/images/tummyTuck3.png`,
    category: "Tummy tuck",
    title: "Tummy tuck",
  },
  {
    url: `${CDN_HOST}/images/tummyTuck4.png`,
    category: "Tummy tuck",
    title: "Tummy tuck",
  },
  {
    url: `${CDN_HOST}/images/tummyTuck5.png`,
    category: "Tummy tuck",
    title: "Tummy tuck",
  },
  {
    url: `${CDN_HOST}/images/tummyTuck6.png`,
    category: "Tummy tuck",
    title: "Tummy tuck",
  },
];

export default tummyTuckMock;