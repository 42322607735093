import React from "react";
import { useTranslation } from "react-i18next";
import { Banner, ScrollToTop } from "components/atoms";
import { Header } from "components/molecules";
import CDN_HOST from "config";

import "./Facilities.scss";

const Facilities = () => {
  const { t } = useTranslation("facilities");
  return (
    <div className="Facilities">
      <ScrollToTop />
      <Header />
      <Banner title="Facilities" />
      <div className="wrapper">
        <div className="title-container">
          <p className="title">{t("facilities:title")}</p>
          <div className="separator"></div>
        </div>
        <div className="accommodation">
          <p className="subtitle">{t("facilities:accommodation")}</p>
          <p className="detail">{t("facilities:accommodationDesc")}</p>
          <div className="img-accommodation-container">
            <div className="row-1">
              <img
                src={`${CDN_HOST}/images/facilitie1.jpg`}
                alt="Facilitie"
              />
              <img
                src={`${CDN_HOST}/images/facilitie2.jpg`}
                alt="Facilitie"
              />
            </div>
            <div className="row-2">
              <img
                src={`${CDN_HOST}/images/facilitie3.jpg`}
                alt="Facilitie"
              />
              <img
                src={`${CDN_HOST}/images/facilitie4.jpg`}
                alt="Facilitie"
              />
              <img
                src={`${CDN_HOST}/images/facilitie5.jpg`}
                alt="Facilitie"
              />
            </div>
            <div className="row-3">
              <img
                src={`${CDN_HOST}/images/facilitie6.jpg`}
                alt="Facilitie"
              />
              <img
                src={`${CDN_HOST}/images/facilitie7.jpg`}
                alt="Facilitie"
              />
            </div>
            <div className="row-4">
              <img
                src={`${CDN_HOST}/images/facilitie8.jpg`}
                alt="Facilitie"
              />
              <img
                src={`${CDN_HOST}/images/facilitie9.jpg`}
                alt="Facilitie"
              />
            </div>
          </div>
        </div>
        <div className="clinic">
          <p className="subtitle">{t("facilities:clinic")}</p>
          <p className="detail">{t("facilities:clinicDesc")}</p>
          <div className="img-clinic-container">
            <div className="row-1">
              <img
                src={`${CDN_HOST}/images/hospital1.png`}
                alt="Hospital"
              />
              <img
                src={`${CDN_HOST}/images/hospital2.jpg`}
                alt="Hospital"
              />
            </div>
            <div className="row-2">
              <img
                src={`${CDN_HOST}/images/hospital3.jpg`}
                alt="Hospital"
              />
              <img
                src={`${CDN_HOST}/images/hospital4.jpg`}
                alt="Hospital"
              />
            </div>
            <div className="row-3">
              <img
                src={`${CDN_HOST}/images/hospital5.jpg`}
                alt="Hospital"
              />
              <img
                src={`${CDN_HOST}/images/hospital6.jpg`}
                alt="Hospital"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facilities;
