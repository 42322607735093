import CDN_HOST from "../config";

const breastAugmentationMock = [
  {
    url: `${CDN_HOST}/images/breastAugmentation1.svg`,
    category: "Breast Augmentation",
    title: "Breast Augmentation",
  },
  {
    url: `${CDN_HOST}/images/breastAugmentation2.svg`,
    category: "Breast Augmentation",
    title: "Breast Augmentation",
  },
  {
    url: `${CDN_HOST}/images/breastAugmentation3.svg`,
    category: "Breast Augmentation",
    title: "Breast Augmentation",
  },
];

export default breastAugmentationMock;