import React from "react";
import { useTranslation } from "react-i18next";
import { Banner, ScrollToTop } from "components/atoms";
import { ValueCard, Header } from "components/molecules";
import { valuesMock } from "data";

import CDN_HOST from "config";

import "./AboutUs.scss";



const AboutUs = () => {
  const { t } = useTranslation("aboutUs");
  return (
    <div className="AboutUs">
      <ScrollToTop />
      <Header />
      <Banner title="About us" />
      <div className="section-one">
        <div className="column-left">
          <p className="title-left">{t("aboutUs:whoAreWeTitle")}</p>
          <p className="info">
            {t("aboutUs:description")}
            <br />
            <br />
            {t("aboutUs:whoAreWeInfo")}
            <br />
            <br />
            {t("aboutUs:whoAreWeName")}
          </p>
        </div>
        <div className="column-right">
          <div className="image">
            <img
              src={`${CDN_HOST}/images/aboutUs.jpg`}
              alt="About Us"
            />
          </div>
        </div>
      </div>
      <div className="section-two">
        <div className="column-left">
          <div className="image">
            <img
              src={`${CDN_HOST}/images/missionAndVision.png`}
              alt="About us img"
            />
          </div>
        </div>
        <div className="column-right">
          <p className="subtitle">{t("aboutUs:missionTitle")}</p>
          <p className="info">{t("aboutUs:missionInfo")}</p>
          <p className="subtitle">{t("aboutUs:visionTitle")}</p>
          <p className="info">{t("aboutUs:visionInfo")}</p>
        </div>
      </div>
      <div className="section-three">
        <p className="title">{t("aboutUs:ourCoreValuesTitle")}</p>
        <div className="values-container">
          {valuesMock.map((card, idx) => {
            return (
              <ValueCard
                key={idx}
                urlImg={card.urlImg}
                title={card.title}
                subtitle={card.subtitle}
                description={card.description}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
