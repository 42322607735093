import React from "react";
import { Banner, ScrollToTop } from "components/atoms";
import { Header, TeamCard } from "components/molecules";

import { TeamList, Title, TeamContainer, Description } from "./styles";
import { Wrapper } from "shared/index";

import { teamMock } from "data";

const Team = () => {
  return (
    <div>
      <ScrollToTop />
      <Header />
      <Banner title="Team" />
      <TeamContainer>
        <Wrapper>
          <Title>
            Meet our team <div className="separator" />
          </Title>
          <Description>
            <p>
              At Primavera Surgical & Tourism we want you to feel you have
              options to be and improve your self image. When you are looking to
              go for a cosmetic procedure take your time and choose those who
              are experienced, reputable and board-certified. Choosing a
              board-certified surgeon with plenty of experience will give you
              peace of mind that the procedure will be successful because you’re
              confident that you’re under the hands of an expert. Therefore, we
              give you assurance that the Surgeons we have partnered with are
              the best in Medellin, Colombia and Certified by the Colombian
              Association of Plastic Surgeons. Our Final result is for you to
              Feel more confident in your own skin.
            </p>
          </Description>
          <TeamList>
            {teamMock.map((person) => (
              <TeamCard
                name={person.name}
                photo={person.photo}
                subTitle={person.subTitle}
                title={person.title}
              />
            ))}
          </TeamList>
        </Wrapper>
      </TeamContainer>
    </div>
  );
};

export default Team;
