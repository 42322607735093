import styled from "styled-components";
import { colors, device, fontWeight, fontFamily } from "styles";

export const TestimonialCardContainer = styled.div`
  border-left: 4px solid ${colors.secondary};
  display: grid;
  grid-template-areas:
    "user-img"
    "testimonial"
    "name-profession";
  column-gap: 30px;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.06);
  position: relative;
  background-color: ${colors.white};

  @media ${device.medium} {
    grid-template-columns: 100px auto;
    grid-template-areas:
      "user-img testimonial"
      "user-img name-profession";
  }

  @media ${device.large} {
    width: 450px;
  }

  @media ${device.extraLarge} {
    width: 570px;
  }

  &::before {
    content: " ";
    position: absolute;
    right: 0;
    bottom: 0;
    border-top: 25px solid ${colors.secondary};
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    transform: rotate(-45deg);
    transform-origin: 80% -30% 0;
  }
`;

export const UserImg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  grid-area: user-img;
  height: 80px;
  width: 80px;
  background-color: ${colors.grey};
  justify-self: center;
  border-radius: 50%;
  margin-top: 20px;

  @media ${device.medium} {
    margin-top: 0;
  }

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const TestimonialContainer = styled.div`
  grid-area: testimonial;
  text-align: center;
  padding: 20px;

  @media ${device.medium} {
    padding: 30px 30px 0 0;
    text-align: left;
  }
`;

export const Testimonial = styled.p`
  color: ${colors.black};
  font-family: ${fontFamily.secondaryFont};
  font-weight: ${fontWeight.black};
  margin-bottom: 25px;
`;

export const NameProfession = styled.div`
  padding-bottom: 30px;
  grid-area: name-profession;
  color: ${colors.black};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${fontFamily.primaryFont};

  @media ${device.medium} {
    justify-content: flex-start;
  }
`;

export const Name = styled.p`
  font-size: 20px;
  font-weight: ${fontWeight.black};
  text-transform: uppercase;
  text-align: center;
`;
