import CDN_HOST from "../config";

const otoplastyMock = [
  {
    url: `${CDN_HOST}/images/otoplasty1.svg`,
    category: "Otoplasty",
    title: "Otoplasty",
  },
  {
    url: `${CDN_HOST}/images/otoplasty2.svg`,
    category: "Otoplasty",
    title: "Otoplasty",
  },
  {
    url: `${CDN_HOST}/images/otoplasty3.svg`,
    category: "Otoplasty",
    title: "Otoplasty",
  },
  {
    url: `${CDN_HOST}/images/otoplasty4.svg`,
    category: "Otoplasty",
    title: "Otoplasty",
  },
  {
    url: `${CDN_HOST}/images/otoplasty5.svg`,
    category: "Otoplasty",
    title: "Otoplasty",
  },
];

export default otoplastyMock;