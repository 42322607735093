import CDN_HOST from "../config";

const homeServicesMock = [
  {
    id: 1,
    photo: `${CDN_HOST}/images/face.jpg`,
    title: "Face",
    url: "/services/face/rhinoplasty",
  },
  {
    id: 2,
    photo: `${CDN_HOST}/images/breast.jpg`,
    title: "Breast",
    url: "/services/breast/breast-augmentation",
  },
  {
    id: 3,
    photo: `${CDN_HOST}/images/body.jpg`,
    title: "Body",
    url: "/services/body/tummy-tuck",
  },
];

export default homeServicesMock;
