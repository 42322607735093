import CDN_HOST from "../config";

const breastLiftMock = [
  {
    url: `${CDN_HOST}/images/breastLift1.png`,
    category: "Breast Lift",
    title: "Breast Lift",
  },
];

export default breastLiftMock;