import CDN_HOST from "../config";

const homeFacilitiesMock = [
  {
    img: `${CDN_HOST}/images/facilitie1.jpg`,
    title: "facilities:safety",
  },
  {
    img: `${CDN_HOST}/images/hospital3.jpg`,
    title: "facilities:technology",
  },
  {
    img: `${CDN_HOST}/images/facilitie9.jpg`,
    title: "facilities:professionalism",
  },
];

export default homeFacilitiesMock;
