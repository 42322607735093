import CDN_HOST from "../config";

const breastReductionMock = [
  {
    url: `${CDN_HOST}/images/breastReduction1.png`,
    category: "Breast Reduction",
    title: "Breast Reduction",
  },
  {
    url: `${CDN_HOST}/images/breastReduction2.jpg`,
    category: "Breast Reduction",
    title: "Breast Reduction",
  },
];

export default breastReductionMock;