import React from "react";
import { useTranslation } from "react-i18next";
import { ScrollToTop } from "components/atoms";
import { Header } from "components/molecules";
import { TourismVideoContainer, TourismInfoContainer} from "./styles";
import styled from "styled-components";
import "./Tourism.scss";

import CDN_HOST from "config";

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: "cover";
  object-position: center center;
  opacity: 1;
`;
const Tourism = () => {
  const { t } = useTranslation("tourism");
  return (
    <div className="Tourism">
      <ScrollToTop />
      <Header />
      <TourismVideoContainer>
        <Video
          muted
          loop
          autoPlay
          src={`${CDN_HOST}/images/tourismVideo.mp4`}
        ></Video>
      </TourismVideoContainer>
      <TourismInfoContainer>
        <h1>{t("tourism:tourismTitle")}</h1>
        <p>
        {t("tourism:tourismInfoOne")}
        </p>
        <br />
        <p>
        {t("tourism:tourismInfoTwo")}
        </p>
      </TourismInfoContainer>
      <div className="destinations-container">
        <p className="title"> {t("tourism:tourismSubtitle")}</p>
        <div className="images-container">
          <div className="row-one">
           <div className="one">
           <div className="text">
              <p>{t("tourism:medellin")}</p>
            </div>
            <div className="image">
              <img src={`${CDN_HOST}/images/medellin1.jpg`} alt="Medellin 1"></img>
            </div>
            <div className="image">
              <img src={`${CDN_HOST}/images/medellin2.jpg`} alt="Medellin 2"></img>
            </div>
           </div>
            <div className="two">
            <div className="image">
              <img src={`${CDN_HOST}/images/medellin3.jpg`} alt="Medellin 3"></img>
            </div>
            <div className="image">
              <img src={`${CDN_HOST}/images/medellin4.jpg`} alt="Medellin 4"></img>
            </div>
            <div className="image">
              <img src={`${CDN_HOST}/images/medellin5.jpg`} alt="Medellin 5"></img>
            </div>
            </div>
          </div>
          <div className="row-two">
           <div className="one">
           <div className="text">
              <p>{t("tourism:cartagena")}</p>
            </div>
            <div className="image">
              <img src={`${CDN_HOST}/images/cartagena1.jpg`} alt="Cartagena 1"></img>
            </div>
            <div className="image">
              <img src={`${CDN_HOST}/images/cartagena2.jpg`} alt="Cartagena 2"></img>
            </div>
            <div className="image">
              <img src={`${CDN_HOST}/images/cartagena3.jpg`} alt="Cartagena 3"></img>
            </div>
            <div className="image">
              <img src={`${CDN_HOST}/images/cartagena4.jpg`} alt="Cartagena 4"></img>
            </div>
            <div className="image">
              <img src={`${CDN_HOST}/images/cartagena5.jpg`} alt="Cartagena 5"></img>
            </div>
            </div>
          </div>
          <div className="row-three">
           <div className="one">
           <div className="text">
              <p>{t("tourism:ejeCafetero")}</p>
            </div>
            <div className="image">
              <img src={`${CDN_HOST}/images/ejeCafetero1.jpg`} alt="Eje Cafetero 1"></img>
            </div>
            <div className="image">
              <img src={`${CDN_HOST}/images/ejeCafetero2.jpg`} alt="Eje Cafetero 2"></img>
            </div>
            <div className="image">
              <img src={`${CDN_HOST}/images/ejeCafetero3.jpg`} alt="Eje Cafetero 3"></img>
            </div>
            <div className="image">
              <img src={`${CDN_HOST}/images/ejeCafetero4.jpg`} alt="Eje Cafetero 4"></img>
            </div>
            <div className="image">
              <img src={`${CDN_HOST}/images/ejeCafetero5.jpg`} alt="Eje Cafetero 5"></img>
            </div>
            </div>
          </div>
          <div className="row-four">
           <div className="one">
           <div className="text">
              <p>{t("tourism:santaMarta")}</p>
            </div>
            <div className="image">
              <img src={`${CDN_HOST}/images/santaMarta1.jpg`} alt="Santa Marta 1"></img>
            </div>
            <div className="image">
              <img src={`${CDN_HOST}/images/santaMarta2.jpg`} alt="Santa Marta 2"></img>
            </div>
            <div className="image">
              <img src={`${CDN_HOST}/images/santaMarta3.jpg`} alt="Santa Marta 3"></img>
            </div>
            <div className="image">
              <img src={`${CDN_HOST}/images/santaMarta4.jpg`} alt="Santa Marta 4"></img>
            </div>
            <div className="image">
              <img src={`${CDN_HOST}/images/santaMarta5.jpg`} alt="Santa Marta 5"></img>
            </div>
            </div>
          </div>
        </div>
        <div className="extra-container">
          <p className="disclaimer">{t("tourism:disclaimer")}</p>
          <div className="link-container">
            <a href="https://wa.link/k2j21t" className="link">{t("tourism:getAQuote")}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tourism;
