import React from "react";
import { useTranslation } from "react-i18next";
import CDN_HOST from "config";

import "./WhyChooseUs.scss";

const WhyChooseUs = () => {
  const { t } = useTranslation("whyChooseUs");
  return (
    <div className="WhyChooseUs">
      <div className="image-container">
        <img
          src={`${CDN_HOST}/images/whyChooseUs.png`}
          alt="Why Us"
        />
      </div>
      <div className="right-container">
        <div className="info-container">
          <p className="title">{t("whyChooseUs:whyChooseUs")}</p>
          <div className="info-box">
            <p className="details">{t("whyChooseUs:infoOne")}</p>
          </div>
          <div className="info-box">
            <p className="details">{t("whyChooseUs:infoTwo")}</p>
          </div>
          <div className="info-box">
            <p className="details">{t("whyChooseUs:infoThree")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
