import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors, device, fontFamily } from "styles";

export const VerticalServiceCardContainer = styled(Link)`
  width: 290px;
  height: 492px;
  position: relative;
  margin: 15px;
  
  @media ${device.large} {
      margin-top: -25px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .img-overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

export const CardContent = styled.div`
  position: absolute;
  bottom: 40px;
  left: 0;
  z-index: 2;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  color: ${colors.white};
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 30px;
    font-family: ${fontFamily.primaryFont};

    &::after {
      content: "";
      width: 50px;
      height: 2px;
      margin: 5px auto;
      background-color: ${colors.white};
      display: block;
      position: relative;
    }
  }

  p {
    font-family: ${fontFamily.secondaryFont};
    font-size: 16px;
    letter-spacing: 0.34px;
  }
`;

export const CustomBorderLeft = styled.span`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;

  /* &:hover {
    &::before {
      height: calc(100% - 1rem * 2);
    }

    &::after {
      width: calc(100% - 1rem * 2);
    }
  } */

  &::before {
    top: 1rem;
    left: 1rem;
    position: absolute;
    content: "";
    background: ${colors.white};
    z-index: 1;
    transition: width 0.4s ease, height 0.4s ease;
    width: 1px;
    height: 80%;
  }

  &::after {
    top: 1rem;
    left: 1rem;
    position: absolute;
    content: "";
    background: ${colors.white};
    z-index: 1;
    transition: width 0.4s ease, height 0.4s ease;
    width: 80%;
    height: 1px;
  }
`;

export const CustomBorderRight = styled.span`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;

  /* &:hover {
    &::before {
      height: calc(100% - 1rem * 2);
    }

    &::after {
      width: calc(100% - 1rem * 2);
    }
  } */

  &::before {
    bottom: 1rem;
    right: 1rem;
    position: absolute;
    content: "";
    background: ${colors.white};
    z-index: 1;
    transition: width 0.4s ease, height 0.4s ease;
    width: 1px;
    height: 80%;
  }

  &::after {
    bottom: 1rem;
    right: 1rem;
    position: absolute;
    content: "";
    background: ${colors.white};
    z-index: 1;
    transition: width 0.4s ease, height 0.4s ease;
    width: 80%;
    height: 1px;
  }
`;
