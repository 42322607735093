import React from "react";
import { Banner, ScrollToTop } from "components/atoms";
import { Header } from "components/molecules";
import { InfoCard } from "components/molecules";
import { Wrapper } from "shared";
import { servicesMock } from "data";

import "./Services.scss";

const Services = () => {
  return (
    <div className="Services">
      <ScrollToTop />
      <Header />
      <Banner title="Services" />
      <Wrapper className="cards-container">
        {servicesMock.map((card, idx) => {
          return (
            <InfoCard
              key={idx}
              urlImg={card.urlImg}
              title={card.title}
              linkTo={card.linkTo}
            />
          );
        })}
      </Wrapper>
    </div>
  );
};
export default Services;
