import styled from "styled-components";
import { device, colors, fontFamily, fontWeight } from "styles";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  width: 100vw;
  padding: 60px 12px 0 12px;
  margin: 0 auto;
  box-sizing: border-box;

  @media ${device.medium} {
    width: 768px;
    margin-top: 3.5rem;
  }

  @media ${device.large} {
    width: 992px;
    padding: 0 30px;
  }

  @media ${device.extraLarge} {
    width: 1200px;
    padding: 0 124px;
  }
`;

export const Button = styled(Link)`
  border-radius: 4px;
  border: 2px solid ${colors.grey};
  color: ${colors.white};
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 5px;
  text-align: center;
  text-transform: capitalize;
  vertical-align: middle;
  font-weight: ${fontWeight.black};
  font-family: ${fontFamily.secondaryFont};

  @media ${device.small} {
    font-size: 16px;
    letter-spacing: 2px;
    padding: 10px 25px;
    width: fit-content;
  }
`;

export const StyledTitle = styled.h1`
  color: ${(props) => props.color || "black"};
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.9px;
  padding-bottom: 20px;
  font-size: 46px;
`;
