import React from "react";

import "./ValueCard.scss";


interface ValueCardProps {
  urlImg: string;
  title: string;
  subtitle: string;
  description: string;
}

const ValueCard = ({ urlImg, title, subtitle, description }: ValueCardProps) => {
  return (
    <div className="ValueCard">
      <div className="img-container">
        <img src={urlImg} alt={title} />
      </div>
      <div className="info-container">
        <p className="title">{title}</p>
        <p className="subtitle">{subtitle}</p>
        <p className="description">{description}</p>
      </div>
    </div>
  );
};

export default ValueCard;