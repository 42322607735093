import React from "react";

import {
  ClientImageContainer,
  ClientName,
  ClientTestimonial,
  InfoContainer,
  TestimonialSlotContainer,
} from "./style";

interface TestimonialSlotProps {
  name: string;
  photoUrl: string;
  testimonial: string;
}

const TestimonialSlot = ({
  name,
  photoUrl,
  testimonial,
}: TestimonialSlotProps) => {
  return (
    <TestimonialSlotContainer>
      <ClientImageContainer>
        <img src={photoUrl} alt="Testimonial slot" />
      </ClientImageContainer>
      <InfoContainer>
        <ClientName>{name}</ClientName>
        <ClientTestimonial>
          {testimonial}
        </ClientTestimonial>
      </InfoContainer>
    </TestimonialSlotContainer>
  );
};

export default TestimonialSlot;
