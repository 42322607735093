import React from "react";
import {
  TestimonialCardContainer,
  UserImg,
  TestimonialContainer,
  Testimonial,
  NameProfession,
  Name,
} from "./styles";

interface TestimonialCardProps {
  img: string;
  testimonial: string;
  name: string;
}

const TestimonialCard = ({
  img,
  testimonial,
  name,
}: TestimonialCardProps) => {
  return (
    <TestimonialCardContainer>
      <UserImg>
        <img src={img} alt="Testimonial card img" />
      </UserImg>
      <TestimonialContainer className="testimonial">
        <Testimonial>{testimonial}</Testimonial>
      </TestimonialContainer>
      <NameProfession>
        <Name className="name">{name}</Name>
      </NameProfession>
    </TestimonialCardContainer>
  );
};

export default TestimonialCard;
