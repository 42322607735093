const valuesMock = [
  {
    urlImg: "https://image.flaticon.com/icons/png/512/1320/1320521.png",
    title: "Safety",
    subtitle: "Your Safety is Our Priority!",
    description:
      "The safety and protection of our clients is very important to us.  We have implemented all safety and hygiene regulations within our team  and our partners.  Primavera Surgical & Tourism will continue to create available measures to inform our clients of any changes. We want to make YOU feel safe at all times.",
  },
  {
    urlImg: "https://image.flaticon.com/icons/png/512/1022/1022377.png",
    title: "Transparency",
    subtitle: "We are Transparent!",
    description:
      "We invite you to believe in our Values. Primavera Surgical & Tourism is established as an honest and credible team. The prospect of being open and vulnerable makes our digital & physical revolution real.",
  },
  {
    urlImg: "https://image.flaticon.com/icons/png/512/4221/4221168.png",
    title: "Passion",
    subtitle: "We do everything with Passion!",
    description:
      "It has become a part of who we are.  Primavera Surgical & Tourism has integrated its Passion in every service we provide to our guests.",
  },
  {
    urlImg: "https://image.flaticon.com/icons/png/512/3798/3798693.png",
    title: "Responsibility",
    subtitle: "We take full Responsibility with our Actions!",
    description:
      "Our Clients are the most important and valuable asset of our brand. We will always provide top-quality services to YOU. Primavera Surgical & Tourism is responsible for the growth, stability, direction and daily operation of the services we  provide.",
  },
  {
    urlImg: "https://image.flaticon.com/icons/png/512/3315/3315020.png",
    title: "Trust",
    subtitle: "We Believe in Fundamental Trust!",
    description:
      "Integrity is the keystone of our business with our clients. Primavera Surgical & Tourism is committed to maintain character and follow through on what we say we will do.  We want to make fruitful connections and develop a business model that works for both our Clients and Company.",
  },
];

export default valuesMock;