import CDN_HOST from "../config";

const homeTestimonialsMock = [
  {
    name: "Paula Romero",
    testimonial:
      "Thank you Primavera for your amazing Service while I was in Medellin. I went to Colombia in April 2021  for a Breast Augmentation. I am from Spain and Stayed in the city for 3 Weeks. I had an incredible experience and recovery. Primavera’s Team was super helpful in every way. The food was amazing and Maria, the nurse who was with me for the first week, was a sweetheart. She was with me 24/7 for the first 3 days as those were the hardest. I had an amazing companionship from Melissa, she is such a professional individual who always assisted me and showed me the best spots in Medellin city.",
    photoUrl: `${CDN_HOST}/images/PaulaRomero.jpg`,
  },
  {
    name: "Jonathan Redondo",
    testimonial:
      "I went to Medellin City in March of 2021, I am from New Jersey and came down to Colombia for a Smile design. I love my Results, I am more confident with my smile now thanks to Dr. Duque and Primavera Surgical & Tourism for their care and services. I stayed in the city for 2 weeks and had full companionship during my procedure, and also enjoyed the tourism Medellin city has to offer. Primavera’s Director Melissa was Super Professional, she took the time to give me an amazing experience in the city while I was there. I don’t doubt that whoever books with Primavera Surgical & Tourism won't regret it. It is life changing!",
    photoUrl: `${CDN_HOST}/images/JonathanRedondo.jpg`,
  },
  {
    name: "Omar Alvarez",
    testimonial:
      "I am from Mexico City, I went to Medellin for a month and had my Lasik eye surgery done with Dr. Boris. Thank you Primavera Surgical and Tourism for being part of my process. You have amazing professionals who you’ve partnered with. Melissa the Director was by my side during the whole process. She took the time to show me around the city. Medellin is one of the best cities I have been to, the people, food and tourism experience was beyond my belief. Thank you Primavera for being part of my experience.",
    photoUrl: `${CDN_HOST}/images/OmarAlvarez.jpg`,
  },
];

export default homeTestimonialsMock;
