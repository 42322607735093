import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  Home,
  AboutUs,
  ContactUs,
  Gallery,
  Services,
  Face,
  Breast,
  Body,
  Dentistry,
  LasikEye,
  Testimonials,
  Team,
  Facilities,
  AllInclusive,
  PrivacyPolicy,
  Tourism,
} from "components/views";
import { Footer } from "components/molecules";
import ScrollTop from "globals/ScrollTop";
import "./App.scss";

function App() {
  return (
    <Suspense fallback="loading">
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/all-inclusive" component={AllInclusive} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/facilities" component={Facilities} />
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/services/dentistry" component={Dentistry} />
          <Route exact path="/services/lasik-eye" component={LasikEye} />
          <Route exact path="/team" component={Team} />
          <Route exact path="/testimonials" component={Testimonials} />
          <Route exact path="/tourism" component={Tourism} />
          <Route path="/services/body" component={Body} />
          <Route path="/services/breast" component={Breast} />
          <Route path="/services/face" component={Face} />
        </Switch>
        <Footer />
        <ScrollTop />
      </Router>
    </Suspense>
  );
}

export default App;
