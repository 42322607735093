import CDN_HOST from "../config";

const bucalFatRemovalMock = [
  {
    url: `${CDN_HOST}/images/bucalFat1.svg`,
    category: "Bucal Fat Removal",
    title: "Bucal Fat Removal",
  },
  {
    url: `${CDN_HOST}/images/bucalFat2.svg`,
    category: "Bucal Fat Removal",
    title: "Bucal Fat Removal",
  },
  {
    url: `${CDN_HOST}/images/bucalFat3.svg`,
    category: "Bucal Fat Removal",
    title: "Bucal Fat Removal",
  },
  {
    url: `${CDN_HOST}/images/bucalFat4.svg`,
    category: "Bucal Fat Removal",
    title: "Bucal Fat Removal",
  },
];

export default bucalFatRemovalMock;