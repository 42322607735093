import React from "react";
import { Banner, ScrollToTop } from "components/atoms";
import { TestimonialCard, Header } from "components/molecules";
import { Wrapper } from "shared";

import "./Testimonials.scss";

import { testimonialMock } from "data";

const Testimonials = () => {

  return (
    <div className="Testimonials">
      <ScrollToTop />
      <Header />
      <Banner title="Testimonials" />
      <Wrapper className="testimonials-wrapper">
        {testimonialMock.map((card, idx) => {
          return (
            <TestimonialCard
              key={idx}
              img={card.url}
              name={card.name}
              testimonial={card.testimonial}
            />
          );
        })}
      </Wrapper>
    </div>
  );
};
export default Testimonials;
