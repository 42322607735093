import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { ScrollToTop } from "components/atoms";
import "./Banner.scss";
import useBreadcrumbs from "use-react-router-breadcrumbs";
interface BannerProps {
  title: string;
}

const Banner = ({ title }: BannerProps) => {
  const breadcrumbs = useBreadcrumbs();

  return (
    <div className="Banner">
      <ScrollToTop />
      <div className="banner-img">
        <h3>{title}</h3>
      </div>
      <div className="banner-path">
        {breadcrumbs.map(({ match, breadcrumb }) => (
          <>
            <span key={match.url}>
              <Link to={match.url}>
                {breadcrumb}
              </Link>
            </span>
            <AiOutlineDoubleRight size="13" />
          </>
        ))}

        {/* <Link to="/">Home </Link>
        
        <Link to={`/${title.toLowerCase().replace(" ", "-")}`}> {title}</Link> */}
      </div>
    </div>
  );
};

export default Banner;
