import styled from "styled-components";
import { device, fontFamily, colors, fontWeight } from "styles";

export const TourismVideoContainer = styled.div`
  position: relative;
  background-color: rgb(221, 221, 221);
`;

export const TourismInfoContainer = styled.div`
  width: 100vw;
  padding: 20px 12px 0 12px;
  margin: 0 auto;
  box-sizing: border-box;

  @media ${device.medium} {
    width: 768px;
  }

  @media ${device.large} {
    position: absolute;
    background-color: #00000073;
    top: 30%;
    left: 2%;
    margin: 0;
    padding: 40px;
    width: 370px;
  }

  @media ${device.extraLarge} {
    top: 30%;
    left: 3%;
    width: 410px;
  }

  > h1 {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${colors.title};
    font-family: ${fontFamily.primaryFont};
    font-size: 26px;
    font-weight: ${fontWeight.black};
    letter-spacing: 0.9px;
    margin-bottom: 20px;
    padding: 20px 0 5px 0;
    text-transform: uppercase;


    @media ${device.medium} {
      font-size: 35px;
    }

    @media ${device.large} {
      margin-bottom: 20px;
      padding: 0;
    }

    &::after {
      content: "";
      background-color: ${colors.title};
      height: 4px;
      width: 40px;
    }
  }

  > p {
    color: black;
    font-size: 17px;
    font-weight: ${fontWeight.bold};

    font-family: ${fontFamily.secondaryFont};
    @media ${device.large} {
      font-size: 16px;
      color: ${colors.white};
    }
  }
`;
