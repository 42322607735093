import CDN_HOST from "../config";

const lasikEyeMock = [
  {
    url: `${CDN_HOST}/images/lasikEye1.png`,
    category: "Lasik Eye",
    title: "Lasik Eye",
  },
  {
    url: `${CDN_HOST}/images/lasikEye2.png`,
    category: "Lasik Eye",
    title: "Lasik Eye",
  },
  {
    url: `${CDN_HOST}/images/lasikEye3.png`,
    category: "Lasik Eye",
    title: "Lasik Eye",
  },
  {
    url: `${CDN_HOST}/images/lasikEye4.png`,
    category: "Lasik Eye",
    title: "Lasik Eye",
  },
];

export default lasikEyeMock;