import CDN_HOST from "../config";

const dentistryMock = [
  {
    url: `${CDN_HOST}/images/dentistry-1.png`,
    category: "dentistry",
    title: "Dentistry",
  },
  {
    url: `${CDN_HOST}/images/dentistry-2.png`,
    category: "dentistry",
    title: "Dentistry",
  },
  {
    url: `${CDN_HOST}/images/dentistry-3.png`,
    category: "dentistry",
    title: "Dentistry",
  },
  {
    url: `${CDN_HOST}/images/dentistry-4.png`,
    category: "dentistry",
    title: "Dentistry",
  },
];

export default dentistryMock;