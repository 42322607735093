import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import "./LabiaReduction.scss";

const LabiaReduction: FC = () => {
  const { t } = useTranslation("body");

  return (
    <div className="LabiaReduction">
        <div className="info-container">
      <p className="title">{t("body:labiaReductionTitle")}</p>
          <div className="main-content">
            <div className="primary-container">
              <div className="left">
                <img
                  src="https://y3p8x5u4.rocketcdn.me/wp-content/uploads/2019/12/thumb.png"
                  alt="Thumbs up"
                />
                <div className="details">
                  <p className="title-details">
                    "{t("body:worthIt")}" {t("body:rating")}
                  </p>
                  <div className="info-details">
                    <p className="number">97%</p>
                    <p className="detail">say it's "worth it"</p>
                  </div>
                </div>
              </div>
              <div className="right">
                <img
                  src="https://y3p8x5u4.rocketcdn.me/wp-content/uploads/2019/12/clock.png"
                  alt="Surgery duration"
                />
                <div className="details">
                  <p className="title-details">{t("body:surgeryLength")}</p>
                  <div className="info-details">
                    <p className="number">
                      {t("body:under")} 60 {t("body:min")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bar-wrapper">
              <p className="title">{t("body:invasiveness")}</p>
              <div className="percentage-container">
                <div className="percentage-eight">
                  <p>8/10</p>
                </div>
              </div>
            </div>
            <div className="bar-wrapper">
              <p className="title">{t("body:discomfortFactor")}</p>
              <div className="percentage-container">
                <div className="percentage-five">
                  <p>5/10</p>
                </div>
              </div>
            </div>
            <div className="secondary-container">
              <div className="content">
                <p className="title-content">{t("body:sypnosis")}</p>
                <p className="info-content">
                  {t("body:labiaReductionSypnosis")}
                </p>
              </div>
              <div className="content">
                <p className="title-content">{t("body:recovery")}</p>
                <p className="info-content">
                  {t("body:labiaReductionRecoveryTrip")}
                </p>
                <p className="info-content">
                  {t("body:labiaReductionRecoveryExercise")}
                </p>
              </div>
              <div className="content">
                <p className="title-content">{t("body:anesthesia")}</p>
                <p className="info-content">{t("body:generalAnesthesia")}</p>
              </div>
              <div className="content">
                <p className="title-content">{t("body:alternativeNames")}</p>
                <p className="info-content">
                  {t("body:labiaReductionAltNames")}
                </p>
              </div>
            </div>
          </div>
          <div className="definition-container">
            <p className="title-definition">
              {t("body:whatIsLabiaReductionTitle")}
            </p>
            <p className="definition">{t("body:labiaReductionContent")}</p>
          </div>
          <div className="link-container">
              <a href="https://wa.link/k2j21t" className="link">{t("body:getAQuote")}</a>
            </div>
        </div>
    </div>
  );
};

export default LabiaReduction;